import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Header from "components/partials/Header";
import Register from "components/pages/register/Register";
import Home from "components/pages/home/Home";
import Terms from "components/pages/terms/Terms";
import Dashboard from "components/pages/dashboard/Dashboard";
import NotifPage from "components/pages/notif/NotifPage";
import DashboardForm from "components/subs/form/DashboardForm";
import "./assets/stylesheets/bootstrap5.min.css";
import "./assets/stylesheets/App.css";
import "./assets/stylesheets/animation.css";

import { UserContext, RegisterContextProvider } from "context";

function App() {
  const { userState, fetchUser } = React.useContext(UserContext);
  const { isAuth } = userState;

  const [isFetched, setIsFetched] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      await fetchUser().then(() => setIsFetched(true));
    }
    fetchData();
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        {isFetched ? (
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/terms">
              <Terms />
            </Route>
            <Route exact path="/register">
              <RegisterContextProvider>
                <Register />
              </RegisterContextProvider>
            </Route>

            <Route exact path="/dashboard">
              {isFetched && isAuth ? <Dashboard /> : <Redirect to="/" />}
            </Route>

            <Route exact path="/dashboard/order/:orderType/:co_id">
              {isFetched && isAuth ? (
                <RegisterContextProvider>
                  <DashboardForm />
                </RegisterContextProvider>
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route path="*">
              <NotifPage />
            </Route>
          </Switch>
        ) : (
          ""
        )}
      </div>
    </Router>
  );
}

export default App;
