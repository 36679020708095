import React from "react";
import { UserContext, ProductContext } from "context";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import Spinner from "components/partials/Spinner";
import SubsCard from "components/subs/subscription/SubsCard";

export default function Dashboard() {
  const { userState } = React.useContext(UserContext);
  const { prodState, fetchAllProduct } = React.useContext(ProductContext);
  const { cc_name, customerOrder } = userState;

  const history = useHistory();

  React.useEffect(() => {
    if (!prodState.products) fetchAllProduct(history, "/dashboard");
  }, []);

  const {
    products,
    productPackages,
    productAddons,
    productAddonMatrixs,
    productAddonItems,
  } = prodState;

  const [selectedOrder, setSelectedOrder] = React.useState(null);

  return (
    <div
      className="Dashboard"
      style={{ maxWidth: "63rem", margin: "1rem auto" }}
    >
      <Helmet>
        <title>Voxnet | Dashboard</title>
      </Helmet>
      <div className="container">
        {userState.isLoading || prodState.isLoading ? (
          <Spinner background={true} />
        ) : (
          <div>
            <div className="bg-white">
              <div className="form-title">Dashboard {cc_name || ""}</div>
            </div>
            <div className="SubsContainer bg-white">
              {customerOrder &&
                prodState.products &&
                Object.values(customerOrder).map((customerOrder) => (
                  <SubsCard
                    key={`subcard-${customerOrder.co_id}-${customerOrder.customerOrderProduct}`}
                    customerOrder={customerOrder}
                    product={products?.[customerOrder.customerOrderProduct]}
                    productPackage={
                      productPackages?.[customerOrder.customerOrderProduct][
                        customerOrder.customerOrderPackage.p_id
                      ]
                    }
                    productAddons={
                      productAddons?.[customerOrder.customerOrderProduct]
                    }
                    productAddonItems={productAddonItems}
                    productAddonMatrixs={productAddonMatrixs}
                    handleSelect={() =>
                      setSelectedOrder(
                        selectedOrder !== customerOrder.co_id
                          ? customerOrder.co_id
                          : null
                      )
                    }
                    selected={selectedOrder === customerOrder.co_id}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
