import React, { useContext } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RegisterContext } from "context/RegisterContext";
import { searchUnique } from "api";

export default function PhoneField({
	name = "",
	label = "Field Label",
	cls = "",
	info = "",
	required = false,
	errorMsg = "Harap diisi",
	uniqueAPI = null,
	disabled = false,
	stateGroup = null,
	minLength = 8,
}) {
	const { regisState, handleChange } = useContext(RegisterContext);

	const handleOnChange = (value) => {
		if (stateGroup) {
			let tempObj = regisState[stateGroup] || {};
			tempObj[name] = value;
			handleChange({ [stateGroup]: tempObj });
		} else if (!stateGroup) {
			handleChange({ [name]: value });
		}
	};

	let fieldRef = React.useRef(null);
	const [isUnique, setUnique] = React.useState(true);
	const [isLong, setLong] = React.useState(false);

	async function checkUnique() {
		const unique = await searchUnique(
			`${uniqueAPI}`,
			name,
			`${regisState[name]}`
		);
		setUnique(unique);
	}

	const checkValidity = () => {
		if (uniqueAPI) checkUnique();
		if ((regisState?.[name]?.length || Infinity) < minLength) {
			return setLong(false);
		} else return setLong(true);
	};

	React.useEffect(checkValidity, [regisState[name]]);

	React.useEffect(() => {
		if (!isUnique || ((!isLong || !regisState[name]) && required)) {
			return fieldRef?.current?.numberInputRef?.setCustomValidity(
				"Harap diisi"
			);
		} else {
			return fieldRef?.current?.numberInputRef?.setCustomValidity("");
		}
	}, [isUnique, isLong]);

	return (
		<div
			className={`
        PhoneField mb-3
        ${cls} 
      `}>
			<label>
				{label} {required ? <span style={{ color: "#dc3545" }}>*</span> : ""}
			</label>
			<small className="form-text text-muted float-end">{info}</small>
			<PhoneInput
				containerClass={`
          ${required ? "required" : ""}
          ${uniqueAPI ? "unique" : ""}
        `}
				inputStyle={inputStyle}
				placeholder=""
				inputProps={{
					name,
					required,
					disabled,
					autoComplete: "off",
					minLength,
				}}
				ref={fieldRef}
				enableSearch={true}
				autoFormat={false}
				countryCodeEditable={false}
				country="id"
				onChange={handleOnChange}
				value={`${regisState[stateGroup]?.[name] || regisState[name] || ""}`}
			/>

			<div
				className="invalid-feedback"
				style={{ display: isUnique && isLong ? "none" : "block" }}>
				{!isUnique
					? `${label} Sudah Diambil!`
					: !isLong && required
					? `Minimal ${minLength} karakter`
					: ""}
			</div>
		</div>
	);
}

const inputStyle = {
	display: "block",
	width: "100%",
	padding: "0.375rem 48px",
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1.5",
	height: "39px",
	color: "#212529",
	backgroundColor: "#fff",
	backgroundClip: "padding-box",
	border: "1px solid #ced4da",
	appearance: "none",
	borderRadius: "0.25rem",
	transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
};
