import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { UserContext } from "context";

import Field from "./Field";
import Spinner from "components/partials/Spinner";

export default function Login() {
  const { login, userState } = React.useContext(UserContext);
  const history = useHistory();

  const [credentials, setCredentials] = React.useState({
    email: "",
    password: "",
  });

  const [error, setError] = React.useState(false);

  const handleRedirect = () => history.push("/dashboard");

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const { email, password } = credentials;
    await login(email, password, handleRedirect);
  };

  const handleChange = (name, value) => {
    if (error) setError(false);
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <div className="Login">
      <div
        className="bold"
        style={{
          marginBottom: "1em",
          fontSize: "1.5rem",
          color: "#ff8848",
        }}
      >
        Lihat Langganan Saya
      </div>

      <form onSubmit={handleSubmit}>
        <Field
          label="Email"
          name="email"
          handleChange={handleChange}
          value={credentials.email}
        />
        <Field
          label="Password"
          name="password"
          type="password"
          handleChange={handleChange}
          value={credentials.password}
        />

        <div className="d-grid gap-2 mx-auto" style={{ margin: "1em" }}>
          <button
            className="btn btn-lg btn-block btn-success form-group"
            type="submit"
          >
            {userState.loading ? (
              <Spinner color="white" thick="5px" />
            ) : (
              "Masuk"
            )}
          </button>
        </div>
        <div className="d-grid gap-2 mx-auto">
          <NavLink
            to="/register"
            className="form-group btn btn-outline-success btn-block"
          >
            Tidak punya Akun? Mulai Langganan Saya
          </NavLink>
        </div>
      </form>
    </div>
  );
}
