import { postURL } from "./base";
import { parseToObject, parseToObjectTelephone } from "./parser";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export async function userLogin(email, password) {
  const data = await postURL("/User/login", { email, password }).then((res) => {
    let {
      customerUser,
      customerAddress,
      customerCompanies,
      customerOrder,
      customerOrderAddon,
      customerOrderTelephone,
      auth_token,
    } = res.data.data;

    customerAddress = parseToObject(customerAddress, "cac_id");

    customerOrderAddon = parseToObject(
      customerOrderAddon,
      "co_id",
      "pa_id",
      "pam_id"
    );

    customerOrderTelephone = parseToObjectTelephone(
      customerOrderTelephone,
      "co_id",
      "cot_city",
      "cot_telephone"
    );

    customerOrder = parseToObject(customerOrder, "co_id");

    Object.values(customerOrder).forEach(({ co_id }) => {
      customerOrder[co_id].customerOrderAddon = customerOrderAddon[co_id];
      customerOrder[co_id].customerOrderTelephone =
        customerOrderTelephone[co_id];
    });

    let finalData = {
      ...customerCompanies,
      ...customerUser,
      customerAddress,
      customerOrder,
    };

    cookies.set("auth_email", email, { path: "/", maxAge: 1800 });
    cookies.set("auth_id", auth_token, { path: "/", maxAge: 1800 });

    return finalData;
  });
  return data;
}

export async function userLoginCookie() {
  const { auth_email, auth_id } = cookies.getAll();

  if (!auth_email || !auth_id) throw false;

  const data = await postURL("/User/login", {
    email: auth_email,
    password: auth_id,
  }).then((res) => {
    let {
      customerUser,
      customerAddress,
      customerCompanies,
      customerOrder,
      customerOrderAddon,
      customerOrderTelephone,
    } = res.data.data;

    customerAddress = parseToObject(customerAddress, "cac_id");

    customerOrderAddon = parseToObject(
      customerOrderAddon,
      "co_id",
      "pa_id",
      "pam_id"
    );

    customerOrderTelephone = parseToObjectTelephone(
      customerOrderTelephone,
      "co_id",
      "cot_city",
      "cot_telephone"
    );

    customerOrder = parseToObject(customerOrder, "co_id");

    Object.values(customerOrder).forEach(({ co_id }) => {
      customerOrder[co_id].customerOrderAddon = customerOrderAddon[co_id];
      customerOrder[co_id].customerOrderTelephone =
        customerOrderTelephone[co_id];
    });

    let finalData = {
      ...customerCompanies,
      ...customerUser,
      customerAddress,
      customerOrder,
    };

    return finalData;
  });
  return data;
}

export async function userLogout() {
  cookies.remove("auth_email");
  cookies.remove("auth_id");

  return true;
}
