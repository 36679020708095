import React from "react";

export default function Card({
  cls = "",
  title = "Card Title",
  desc = "Card Description",
  detail = null,
  p_id = null,
  parentCallback,
  selected = false,
}) {
  return (
    <div className={`Card ${cls}`} style={{ cursor: "pointer" }}>
      <div
        className={selected ? "card choosen choosen-popup" : "card popup"}
        onClick={() => parentCallback(p_id)}
        style={{ margin: "0.3rem" }}
      >
        <div className="card-body" style={{ padding: "0.6em" }}>
          <h5 className="card-title">{title}</h5>
          <div
            className="card-text"
            style={{
              marginBottom: "1rem",
              fontSize: "0.9em",
              height: "5rem",
            }}
          >
            {desc}
          </div>
          {detail && (
            <div className="card-text">
              <span className="unit">Rp.</span>{" "}
              <span className="val">
                {" "}
                {Intl.NumberFormat().format(
                  detail.p_name_monthlysubscribe
                )}{" "}
              </span>
              <span className="unit">/Bulan</span>
            </div>
          )}
          <div className="d-grid gap-2 mx-auto">
            <div
              className={`btn btn-block btn${
                selected ? "" : "-outline"
              }-success`}
              onClick={() => parentCallback(p_id)}
              style={{ marginTop: "1.6em" }}
            >
              {`Pilih ${title}`}
            </div>
          </div>

          {detail && (
            <div
              className="detail"
              style={{ borderTop: "1px solid #ccc", marginTop: "1rem" }}
            >
              <div className="card-text" style={detailTextStyle}>
                <span className="val">
                  {" "}
                  {Intl.NumberFormat().format(detail.p_name_telnumber)}{" "}
                </span>
                <span className="unit">Nomor Telepon</span>
              </div>
              <div className="card-text" style={detailTextStyle}>
                <span className="val">
                  {" "}
                  {Intl.NumberFormat().format(detail.p_name_trunknum)}{" "}
                </span>
                <span className="unit">Trunk Lines</span>
              </div>
              <div className="card-text" style={detailTextStyle}>
                <span className="val">
                  {" "}
                  {Intl.NumberFormat().format(detail.p_name_talkcredit)}{" "}
                </span>
                <span className="unit">Talk Credit</span>
              </div>
              {detail.p_name_maxsub && (
                <div className="card-text" style={detailTextStyle}>
                  <span className="val">
                    {" "}
                    {Intl.NumberFormat().format(detail.p_name_maxsub)}{" "}
                  </span>
                  <span className="unit">Sub-Account</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const detailTextStyle = { margin: "0.4em 0" };
