export default function useSuggestion(
  regisState,
  productPackages,
  productAddons,
  productAddonMatrixs
) {
  // Get all user input parameters and store it into one object
  const {
    customerOrderProduct,
    s_p_name_telnumber = 0,
    s_p_name_trunknum = 0,
    s_p_name_talkcredit = 0,
    s_p_name_maxsub = 0,
  } = regisState;

  let suggestions = [];

  // Loop through packages

  Object.values(productPackages).forEach((pack) => {
    // initate suggest object for each package
    let suggest = {
      totalPrice: parseInt(pack.p_name_monthlysubscribe),
      customerOrderPackage: pack,
      customerOrderAddon: {},
      summary: {},
    };

    // declare requested obj to store yield params
    let requested = {
      p_name_telnumber: parseInt(s_p_name_telnumber || 0),
      p_name_trunknum: parseInt(s_p_name_trunknum || 0),
      p_name_talkcredit: parseInt(s_p_name_talkcredit || 0),
      p_name_maxsub: parseInt(s_p_name_maxsub || 0),
    };

    // check if requested talk credit below package's talk credit limit
    if (
      requested["p_name_talkcredit"] <= parseInt(pack["p_name_maxtalkcredit"])
    ) {
      // loop through requested to loop through addon
      Object.keys(requested).forEach((reqParams) => {
        suggest.summary[reqParams] = parseInt(pack[reqParams]);
        pack[reqParams] = pack[reqParams] ? parseInt(pack[reqParams]) : null;
        requested[reqParams] -=
          pack[reqParams] && requested[reqParams] > 0 ? pack[reqParams] : 0;

        if (requested[reqParams] > 0) {
          let pa_id = null;
          if (customerOrderProduct === "1") {
            if (reqParams === "p_name_talkcredit") pa_id = "1";
            if (reqParams === "p_name_telnumber") pa_id = "2";
            if (reqParams === "p_name_trunknum") pa_id = "3";
            if (reqParams === "p_name_maxsub") pa_id = "5";
          } else if (customerOrderProduct === "2") {
            if (reqParams === "p_name_talkcredit") pa_id = "6";
            if (reqParams === "p_name_telnumber") pa_id = "7";
            if (reqParams === "p_name_trunknum") pa_id = "8";
          }

          let productAddonMatrixArray = Object.values(
            productAddonMatrixs[pa_id]
          ).reverse();

          productAddonMatrixArray.forEach(
            ({ pam_value, pa_id, pam_price, pam_id }, pamIdx) => {
              let coa_qty = 0;
              // loop through coa_qty
              while (requested[reqParams] > 0) {
                coa_qty++;
                requested[reqParams] -= parseInt(pam_value);
              }

              // check for exceeded qty
              let pamLength = productAddonMatrixArray.length;
              let isLastPAM = pamIdx + 1 >= pamLength;
              if (requested[reqParams] < 0 && !isLastPAM) {
                coa_qty--;
                requested[reqParams] += parseInt(pam_value);
              }

              // check if a pam above is cheaper than current pam with given coa_qty
              // this case occured on subaccout addon.
              // per 1 November 2021 price list, 5 subaccount x 1 is cheaper than 1 subaccount x 4
              if (pamIdx > 0) {
                let currPamPrice = coa_qty * pam_price;
                let prevPam = productAddonMatrixArray[pamIdx - 1];
                let prevPamPrice = parseInt(prevPam.pam_price);

                if (prevPamPrice < currPamPrice) {
                  // readjust requested reqparams value
                  requested[reqParams] += parseInt(pam_value) * coa_qty;
                  requested[reqParams] -= parseInt(prevPam.pam_value) * 1;

                  // if pam not exist in customerOrderAddon,
                  if (!suggest["customerOrderAddon"][pa_id][prevPam.pam_id]) {
                    suggest["customerOrderAddon"][pa_id][prevPam.pam_id] = {
                      ...prevPam,
                      pa_name: productAddons[pa_id].pa_name,
                      coa_qty: 0,
                    };
                  }
                  // update coa_qty of above pam
                  return suggest["customerOrderAddon"][prevPam.pa_id][
                    prevPam.pam_id
                  ].coa_qty++;
                }
              }

              // push to customerOrderAddon suggestion obj
              if (!suggest["customerOrderAddon"][pa_id]) {
                suggest["customerOrderAddon"][pa_id] = {};
              }
              suggest["customerOrderAddon"][pa_id][pam_id] = {
                pam_id,
                pa_id,
                pam_value,
                pam_price,
                pa_name: productAddons[pa_id].pa_name,
                coa_qty,
              };
            }
          );

          // There's case when buy 1 larger parameter give better price -
          // then buy all fewet parameters combined.
          // so we need if it cheaper to buy the larger parameter instead.
          function countTotalPrice(arr) {
            if (typeof arr === "object") {
              arr = Object.values(arr);
            }
            return arr.reduce(
              (total, curr) => total + parseInt(curr.pam_price) * curr.coa_qty,
              0
            );
          }

          // newCoa and customerOrderAddon refer to same object
          let newCoa = suggest.customerOrderAddon[pa_id];

          Object.values(newCoa).forEach((coa, idx) => {
            if (idx > 0) {
              let prevTotal = countTotalPrice(
                Object.values(newCoa).slice(0, idx + 1)
              );
              let newTotal = (coa.coa_qty + 1) * parseInt(coa.pam_price);

              if (prevTotal >= newTotal) {
                Object.values(newCoa)
                  .slice(0, idx)
                  .forEach((c, idx) => {
                    newCoa[c.pam_id].coa_qty = 0;
                  });
                newCoa[coa.pam_id].coa_qty++;
              }
            }
          });

          // count total of params
          function countTotalParams(arr) {
            if (typeof arr === "object") {
              arr = Object.values(arr);
            }
            return arr.reduce(
              (total, curr) => total + parseInt(curr.pam_value) * curr.coa_qty,
              0
            );
          }
          suggest["totalPrice"] += countTotalPrice(newCoa);
          suggest.summary[reqParams] += countTotalParams(newCoa);
        }
      });
      suggestions.push({ ...suggest, requested });
    }
  });

  // Reorder generated suggestion array and take 3 cheapest combination
  suggestions.sort((a, b) => a.totalPrice - b.totalPrice);
  return suggestions.slice(0, 3);
}
