import React, { useContext, useEffect } from "react";
import StepButtons from "components/forms/partials/subpartials/StepButtons";
import Card from "components/forms/partials/subpartials/Card";
import { RegisterContext, ProductContext } from "context";

import Spinner from "components/partials/Spinner";

export default function ProductForm({ nextStep, isActive }) {
  // const { products } = allProducts;
  const { regisState, handleChange, resetEntry } = useContext(RegisterContext);
  const { prodState } = useContext(ProductContext);

  const { loading, products } = prodState;
  const { customerOrderProduct, orderType } = regisState;

  useEffect(() => {
    if (isActive) window.scrollTo(0, 0);
  }, [isActive]);

  const callback = (p_id) => {
    if (p_id !== regisState.customerOrderProduct) {
      resetEntry();
      handleChange({ orderType });
    }

    // to avoid confusion with p_id of package,
    // store p_id of product (PBX or SIP) to different variable
    handleChange({ 
      customerOrderProduct: p_id, 
      co_name: products?.[p_id]?.p_name 
    });
  };

  return (
    <div className="ProductForm bg-white">
      <div>
        <div className="form-title">Pilih Produk </div>
        <div className="row">
          {loading ? (
            <Spinner />
          ) : products ? (
            Object.values(products).map(
              ({ p_id, p_name, p_name_description }) => (
                <Card
                  key={`card-${p_id}`}
                  cls="col-md-6 col-sm-12"
                  title={p_name}
                  p_id={p_id}
                  selected={customerOrderProduct === p_id}
                  parentCallback={callback}
                  desc={p_name_description}
                />
              )
            )
          ) : (
            ""
          )}
        </div>
        <StepButtons
          next={nextStep}
          nextLabel="Pilih Paket"
          nextDisabled={!regisState.customerOrderProduct}
        />
      </div>
    </div>
  );
}
