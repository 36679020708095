import React, { useContext } from "react";
import { RegisterContext } from "context";
import SingleSelect from "components/forms/partials/fields/SingleSelect";
import Field from "components/forms/partials/fields/Field";
import PhoneField from "components/forms/partials/fields/PhoneField";

export default function AddressContainer() {
  const { regisState, handleChange } = useContext(RegisterContext);
  const { address_diff } = regisState;

  return (
    <div className="AddressContainer bg-white">
      <div className="form-title">Informasi Perusahaan</div>
      <div className="row">
        <Field
          name="cc_name"
          required={true}
          uniqueAPI="/Unique/CustomerCompanies"
          type="text"
          label="Nama Perusahaan"
          cls="col-lg-6 col-xs-12"
        />
        <PhoneField
          name="cc_phone"
          type="number"
          required={true}
          label="Nomor Telepon Perusahaan"
          cls="col-lg-6 col-xs-12"
        />

        <Field
          name="cc_web"
          type="text"
          label="Website Perusahaan"
          cls="col-lg-6 col-xs-12"
        />
      </div>
      <div className="row">
        <Field
          name="cc_npwp"
          type="number"
          label="NPWP Perusahaan"
          info="Faktur Pajak"
          cls="col-lg-6 col-xs-12"
        />
        <Field
          name="cu_name_email"
          disabled={true}
          unique={true}
          type="text"
          label="Alamat Email Penagihan"
          cls="col-lg-6 col-xs-12"
        />
      </div>

      <div className="float-end">
        <span style={{ color: "#dc3545" }}>*</span> Wajib diisi
      </div>

      <div className="row" style={{ marginTop: "3em" }}>
        <div className="col-lg-6 col-xs-12">
          <div className="form-subtitle">Alamat Perusahaan</div>
          <Field
            name="ca_attention"
            stateGroup="address1"
            type="text"
            label="Ditujukan Kepada"
            cls="col-12"
            required={true}
          />
          <Field
            name="ca_address_1"
            stateGroup="address1"
            type="text"
            label="Alamat Perusahaan"
            cls="col-12"
            required={true}
          />
          <SingleSelect
            name="ca_lp_id"
            stateGroup="address1"
            label="Provinsi"
            cls="col-12"
          />
          <SingleSelect
            name="ca_lr_id"
            stateGroup="address1"
            label="Kota/Kabupaten"
            cls="col-12"
          />
          <Field
            name="ca_zipcode"
            stateGroup="address1"
            type="number"
            label="Kode Pos"
            cls="col-12"
            required={true}
          />
          <Field
            name="ca_country"
            stateGroup="address1"
            type="text"
            label="Negara"
            cls="col-12"
            required={true}
          />
          <Field
            name="ca_fax"
            stateGroup="address1"
            type="number"
            label="Nomor Fax"
            cls="col-12"
          />
        </div>

        <div className="col-lg-6 col-xs-12">
          <div className="form-subtitle">
            <span style={{ opacity: address_diff ? "1" : "0.5" }}>
              Alamat Penagihan
            </span>
            <div className="form-check form-switch float-end">
              <input
                className="form-check-input"
                type="checkbox"
                checked={address_diff || false}
                onChange={(evt) =>
                  handleChange({ address_diff: evt.target.checked })
                }
              />
            </div>
          </div>
          {address_diff && (
            <div className="fadein">
              <Field
                name="ca_attention"
                stateGroup="address2"
                type="text"
                label="Ditujukan Kepada"
                cls="col-12"
              />
              <Field
                name="ca_address_1"
                stateGroup="address2"
                type="text"
                label="Alamat Perusahaan"
                cls="col-12"
              />
              <SingleSelect
                name="ca_lp_id"
                stateGroup="address2"
                label="Provinsi"
                cls="col-12"
              />
              <SingleSelect
                name="ca_lr_id"
                stateGroup="address2"
                label="Kota/Kabupaten"
                cls="col-12"
              />
              <Field
                name="ca_zipcode"
                stateGroup="address2"
                type="number"
                label="Kode Pos"
                cls="col-12"
              />
              <Field
                name="ca_country"
                stateGroup="address2"
                type="text"
                label="Negara"
                cls="col-12"
              />
              <Field
                name="ca_fax"
                stateGroup="address2"
                type="number"
                label="Nomor Fax"
                cls="col-12"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
