import React from "react";
import Field from "components/forms/partials/fields/Field";
import PhoneField from "components/forms/partials/fields/PhoneField";

export default function AccountFieldContainer() {
  return (
    <div className="AccountFieldContainer bg-white">
      <div className="form-title">Buat Akun Admin</div>
      <div className="row">
        <Field
          name="cu_name_first"
          required={true}
          type="text"
          label="Nama Depan Pemilik Akun"
          cls="col-lg-6 col-xs-12"
        />
        <Field
          name="cu_name_last"
          type="text"
          label="Nama Belakang Pemilik Akun"
          cls="col-lg-6 col-xs-12"
        />
        {/* <Field
          name="cu_name_dsp"
          type="text"
          label="Display Name Akun Admin"
          cls="col-lg-6 col-xs-12"
        /> */}
        <Field
          name="cu_name_email"
          required={true}
          uniqueAPI="/Unique/User"
          type="email"
          label="Email Akun Admin"
          errorMsg="Harap isi email yang benar"
          cls="col-lg-6 col-xs-12"
        />
        <PhoneField
          name="cu_name_mobile"
          required={true}
          type="number"
          uniqueAPI="/Unique/CustomerUser"
          label="Nomor Handphone Akun Admin"
          cls="col-lg-6 col-xs-12"
          errorMsg="Panjang Minimum 8"
        />
      </div>
      <div className="float-end">
        <span style={{ color: "#dc3545" }}>*</span> Wajib diisi
      </div>
    </div>
  );
}
