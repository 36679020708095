import React from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import Login from "components/forms/login/Login";
import { UserContext } from "context";

export default function Home() {
  const { userState } = React.useContext(UserContext);
  const { isAuth } = userState;

  return (
    <div className="Home">
      <Helmet>
        <title>Selamat datang di Voxnet!</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-md-12 home-register-side">
            <div className="tagline">
              <div className="medium bold">Solusi Anda Untuk</div>
              <div className="large bold">Komunikasi Berbasis Cloud</div>
              <br></br>
              <br></br>
              <div className="small">
                Layanan PBX dan Cloud SIP-Trunks <strong>prepaid</strong>. Bayar
                per bulan, tanpa kontrak
              </div>
              <NavLink to="/register" className="btn btn-lg btn-success">
                Mulai Berlangganan
              </NavLink>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-12"
            style={{
              background: "white",
              height: "94.3vh",
            }}
          >
            {!isAuth && (
              <div
                className="container"
                style={{
                  maxWidth: "35rem",
                  marginTop: "10rem",
                }}
              >
                <Login />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
