import React, { useContext } from "react";
import { RegisterContext } from "context";
import { searchUnique } from "api";

export default function Field({
  type = "text",
  name = "",
  label = "Field Label",
  cls = "",
  info = "",
  required = false,
  errorMsg = "Harap diisi",
  uniqueAPI = null,
  disabled = false,
  stateGroup = null,
  prefix = null,
  min = null,
}) {
  const { regisState, handleChange } = useContext(RegisterContext);

  const handleOnChange = async (evt) => {
    let { value } = evt.target;

    if (stateGroup) {
      let tempObj = regisState[stateGroup] || {};
      tempObj[name] = value;
      handleChange({ [stateGroup]: tempObj });
    } else if (!stateGroup) {
      handleChange({ [name]: value });
    }
  };

  const [unique, setUnique] = React.useState(true);

  let fieldRef = React.useRef(null);

  async function checkUnique() {
    const isUnique = await searchUnique(
      `${uniqueAPI}`,
      name,
      `${prefix || ""}${regisState[stateGroup]?.[name] || regisState[name]}`
    );
    setUnique(isUnique);
    if (!isUnique && fieldRef?.current) {
      return fieldRef.current.setCustomValidity("Sudah Diambil!");
    } else if (fieldRef.current) return fieldRef.current.setCustomValidity("");
  }

  React.useEffect(() => {
    if (uniqueAPI) checkUnique();
  }, [regisState[name]]);

  return (
    <div
      className={`
        Field mb-3 
        ${cls} 
        ${
          regisState[stateGroup]?.[name] || regisState[name]
            ? "was-validated"
            : ""
        }
        ${required ? "required" : ""}
        ${uniqueAPI ? "unique" : ""}
      `}
    >
      <label>
        {label} {required ? <span style={{ color: "#dc3545" }}>*</span> : ""}
      </label>
      <small className="form-text text-muted float-end">{info}</small>
      <div className="input-group mb-3">
        {prefix && (
          <span className="input-group-text" id="basic-addon1">
            {prefix}
          </span>
        )}
        <input
          ref={fieldRef}
          name={name}
          value={regisState[stateGroup]?.[name] || regisState[name] || ""}
          onChange={handleOnChange}
          type={type}
          className="form-control"
          disabled={disabled}
          autoComplete="off"
          required={required}
          min={type === "number" ? min : null}
        />
        {(uniqueAPI || required) && (
          <div className="invalid-feedback">
            {!unique ? `${label} Sudah Diambil!` : errorMsg}
          </div>
        )}
      </div>
    </div>
  );
}
