import React from "react";

export default function NumericUpDown({
  cls = "",
  name = "NumericUpDown",
  value = 0,
  onChangeCallback = null,
  min = -Infinity,
  max = Infinity,
  disabled = false,
  style = {},
}) {
  const onClickPlus = (evt) => {
    evt.preventDefault();
    value = parseInt(value);
    if (value < max) {
      const finalValue = value + 1;
      return onChangeCallback(finalValue);
    }
  };

  const onClickMinus = (evt) => {
    evt.preventDefault();
    value = parseInt(value);
    if (value > min) {
      const finalValue = value - 1;
      return onChangeCallback(finalValue);
    }
  };

  return (
    <span
      style={{
        position: "relative",
        display: "inline-block",
        ...style,
      }}
    >
      <input
        className="form-control"
        style={inputStyle}
        onKeyPress={(evt) => evt.preventDefault()}
        disabled={disabled}
        value={value}
        onChange={() => {}}
        name={name}
        autoComplete="off"
      />
      {/* BUTTON UP */}
      <button
        className={`btn btn-sm ${
          disabled ? "btn-outline-secondary" : "btn-outline-success"
        }`}
        style={plusBtnStyle}
        disabled={disabled}
        onClick={onClickPlus}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          fill="currentColor"
          className="bi bi-plus-lg"
          viewBox="0 0 16 16"
        >
          <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z" />
        </svg>
      </button>
      {/* BUTTON DOWN */}
      <button
        className={`btn btn-sm ${
          disabled ? "btn-outline-secondary" : "btn-outline-success"
        }`}
        style={minusBtnStyle}
        disabled={disabled}
        onClick={onClickMinus}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          fill="currentColor"
          className="bi bi-dash-lg"
          viewBox="0 0 16 16"
        >
          <path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z" />
        </svg>
      </button>
    </span>
  );
}

const inputStyle = {
  textAlign: "center",
  boxSizing: "border-box",
  fontSize: "inherit",
  borderRadius: 2,
  display: "block",
  WebkitAppearance: "none",
  lineHeight: "normal",
  border: "none",
  outlineWidth: 0,
};

const plusBtnStyle = {
  position: "absolute",
  right: 2,
  textAlign: "center",
  cursor: "default",
  transition: "all 0.1s",
  bottom: 1,
  boxShadow: "none",
};

const minusBtnStyle = {
  position: "absolute",
  left: 2,
  textAlign: "center",
  cursor: "default",
  transition: "all 0.1s",
  bottom: 2,
  top: 2,
  right: "auto",
  boxShadow: "none",
};
