import React from "react";
import { NavLink } from "react-router-dom";

export default function SubsCard({
  selected = false,
  product = null,
  productPackage = null,
  productAddons = null,
  productAddonMatrixs = null,
  handleSelect,
  customerOrder = null,
}) {
  const {
    customerOrderAddon = null,
    customerOrderProduct,
    customerOrderTelephone = null,
    co_id = null,
    co_status = null,
    co_expireddate,
  } = customerOrder;

  const addonObjectToArray = () => {
    if (
      customerOrderProduct &&
      customerOrderAddon &&
      productAddons &&
      productAddonMatrixs
    ) {
      let addonArray = [];
      Object.values(customerOrderAddon).forEach((coa) =>
        Object.values(coa).forEach(({ coa_qty, pa_id, pam_id }) => {
          const { pam_value, pam_price } = productAddonMatrixs[pa_id][pam_id];
          const { pa_name } = productAddons[pa_id];
          return addonArray.push({
            coa_qty: parseInt(coa_qty),
            pa_name,
            pam_value,
            pam_price,
          });
        })
      );
      return addonArray;
    }
    return null;
  };

  const [isActive] = React.useState(co_status === "2");

  const addonRow = addonObjectToArray();

  return (
    <div className="card" style={{ margin: "0.3rem" }}>
      <div
        className={selected ? "card-header" : "card-header-not-selected"}
        style={isActive ? { cursor: "pointer" } : notActiveStyle}
        onClick={handleSelect}
      >
        <h5 className="card-title">{`${product?.p_name} ${productPackage?.p_name}`}</h5>
        <div
          className="card-text"
          style={{
            fontSize: "0.9em",
          }}
        >
          {isActive ? `Exp: ${co_expireddate}` : `Paket Belum Aktif`}
        </div>
      </div>

      {selected && (
        <div
          className="card-body"
          style={{ padding: "0.9em", opacity: isActive ? "1.0" : "0.7" }}
        >
          <div className="detail" style={{ margin: "2em auto" }}>
            <div className="row">
              {/* BASIC PACKAGE DETAIL */}
              <div
                className="col-4 position-relative"
                style={{ height: "15em" }}
              >
                <div
                  className="form-subtitle"
                  style={{ textAlign: "center", fontWeight: "600" }}
                >
                  {`Paket ${productPackage?.p_name || "Dasar"}`}
                </div>
                <div className="card-text" style={detailTextStyle}>
                  <span className="val">
                    {`${Intl.NumberFormat().format(
                      productPackage.p_name_telnumber
                    )} `}
                  </span>
                  <span className="unit">Nomor Telepon</span>
                </div>
                <div className="card-text" style={detailTextStyle}>
                  <span className="val">
                    {`${Intl.NumberFormat().format(
                      productPackage.p_name_trunknum
                    )} `}
                  </span>
                  <span className="unit">Trunk Lines</span>
                </div>
                <div className="card-text" style={detailTextStyle}>
                  <span className="val">
                    {`${Intl.NumberFormat().format(
                      productPackage.p_name_talkcredit
                    )} `}
                  </span>
                  <span className="unit">Talk Credit</span>
                </div>
                {productPackage.p_name_maxsub && (
                  <div className="card-text" style={detailTextStyle}>
                    <span className="val">
                      {`${Intl.NumberFormat().format(
                        productPackage.p_name_maxsub
                      )} `}
                    </span>
                    <span className="unit">Sub-Account</span>
                  </div>
                )}
                {isActive && (
                  <div className="position-absolute bottom-0 start-50 translate-middle-x">
                    <div className="d-grid gap-2 mx-auto">
                      <NavLink
                        className="btn btn-block btn-outline-success"
                        style={{ marginTop: "1.6em" }}
                        to={{
                          pathname: `/dashboard/order/extend/${co_id}`,
                          state: { co_id, orderType: "extend" },
                        }}
                      >
                        Perpanjang
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>

              {/* ADDON DETAIL */}
              <div
                className="col-4 position-relative"
                style={{
                  height: "15em",
                  borderRight: "1px solid #ccc",
                  borderLeft: "1px solid #ccc",
                }}
              >
                <div
                  className="form-subtitle"
                  style={{ textAlign: "center", fontWeight: "600" }}
                >
                  Addon
                </div>
                {addonRow
                  ? addonRow.map(({ coa_qty, pa_name, pam_value }, idx) => (
                      <div
                        className="card-text"
                        key={`${pa_name}-${pam_value}-${idx}`}
                      >
                        {`${pa_name}: ${Intl.NumberFormat().format(
                          pam_value
                        )} (${coa_qty})`}
                      </div>
                    ))
                  : "Tidak Ada Addon"}
                {isActive && (
                  <div className="position-absolute bottom-0 start-50 translate-middle-x">
                    <div className="d-grid gap-2 mx-auto">
                      <NavLink
                        className="btn btn-block btn-outline-success"
                        style={{ marginTop: "1.6em" }}
                        to={{
                          pathname: `/dashboard/order/addon/${co_id}`,
                          state: { co_id, orderType: "addon" },
                        }}
                      >
                        Tambah Addon
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>

              {/* TELEPHONE NUMBER DETAIL */}
              <div
                className="col-4 position-relative"
                style={{ height: "15em" }}
              >
                <div
                  className="form-subtitle"
                  style={{ textAlign: "center", fontWeight: "600" }}
                >
                  Nomor Telepon
                </div>
                {customerOrderTelephone
                  ? Object.values(customerOrderTelephone).map(
                      ({ cot_city, cot_telephone }) => (
                        <div key={cot_city}>
                          <div className="card-text">
                            {`${cot_city}: `}
                            {cot_telephone.map((tel, idx) => (
                              <span key={`${cot_city}-${idx}`}>
                                {`${idx !== 0 ? ", " : ""}${tel}`}
                              </span>
                            ))}
                          </div>
                        </div>
                      )
                    )
                  : "Tidak Ada Nomor"}
                {isActive && (
                  <div className="position-absolute bottom-0 start-50 translate-middle-x">
                    <div className="d-grid gap-2 mx-auto">
                      <div
                        className="btn btn-block btn-outline-success"
                        style={{ marginTop: "1.6em" }}
                      >
                        Tambah Nomor
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const detailTextStyle = { margin: "0.4em 0", textAlign: "center" };

const notActiveStyle = {
  backgroundColor: "#999",
};
