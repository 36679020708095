import React, { useContext } from "react";
import { RegisterContext, ProductContext } from "context";

export default function OrderTable({ navComponent = null }) {
  const { regisState } = useContext(RegisterContext);
  const { prodState } = useContext(ProductContext);

  let {
    customerOrderProduct,
    customerOrderPackage,
    customerOrderAddon,
    customerOrderTelephone,
    co_mo_subs,
    orderType = "extend",
  } = regisState;

  let { products, productAddons, productAddonMatrixs, productBonus } =
    prodState;

  productAddons = productAddons?.[customerOrderProduct];

  React.useEffect(() => {
    if (orderType === "addon") {
      customerOrderPackage = null;
    }
  }, []);

  const [totals, setTotals] = React.useState({});

  const countTotalPrice = () => {
    let totalPerMonth = 0;
    totalPerMonth += parseInt(productRow?.p_name_monthlysubscribe || 0);
    if (addonRow) {
      addonRow.forEach(
        ({ coa_qty, pam_price }) =>
          (totalPerMonth += parseInt(coa_qty) * parseInt(pam_price))
      );
    }

    const totalAllMonths = totalPerMonth * (co_mo_subs || 1);
    const ppn = totalAllMonths * 0.1;
    const finalTotal = totalAllMonths + ppn;
    return {
      totalPerMonth: Intl.NumberFormat().format(totalPerMonth),
      ppn: Intl.NumberFormat().format(ppn),
      totalAllMonths: Intl.NumberFormat().format(totalAllMonths),
      finalTotal: Intl.NumberFormat().format(finalTotal),
    };
  };

  React.useEffect(
    () => setTotals(countTotalPrice()),
    [co_mo_subs, customerOrderAddon, customerOrderPackage, customerOrderProduct]
  );

  const productRow =
    customerOrderProduct && customerOrderPackage && orderType !== "addon"
      ? {
          ...customerOrderPackage,
          bonus_credit: Math.round(
            productBonus?.[co_mo_subs || 1].pcb_credit_bonus *
              parseInt(customerOrderPackage.p_name_talkcredit)
          ),
        }
      : null;

  const [creditNoteStar, setCreditNoteStar] = React.useState(2);
  const [telNoteStar, setTelNoteStar] = React.useState(3);

  const addonObjectToArray = () => {
    let addonArray = [];
    Object.values(customerOrderAddon).forEach((pa_id) => {
      Object.values(pa_id).forEach(({ coa_qty, pa_id, pam_id }) => {
        if (parseInt(coa_qty)) {
          const { pam_value, pam_price } = productAddonMatrixs[pa_id][pam_id];
          const { pa_name } = productAddons[pa_id];
          const noteStar =
            parseInt(pa_id) === 1 || parseInt(pa_id) === 6
              ? "*".repeat(creditNoteStar)
              : parseInt(pa_id) === 2 || parseInt(pa_id) === 7
              ? "*".repeat(telNoteStar)
              : "";
          return addonArray.push({
            coa_qty: parseInt(coa_qty),
            pa_name,
            pam_value,
            pam_price,
            noteStar,
          });
        }
      });
    });
    return addonArray;
  };

  const addonRow =
    customerOrderAddon && customerOrderProduct ? addonObjectToArray() : null;

  const countCreditMinute = () => {
    let total = 0;

    if (customerOrderProduct) {
      // Package Talk-Credits
      total += parseInt(customerOrderPackage?.p_name_talkcredit || 0);
      // Bonus Talk-Credits
      const percBonus = productBonus?.[co_mo_subs || 1].pcb_credit_bonus || 0;
      total += total * percBonus;
    }

    // Addon Talk-Credits
    if (
      customerOrderAddon &&
      (customerOrderAddon["1"] || customerOrderAddon["6"])
    ) {
      const customerOrderAddon_tel = customerOrderAddon["1"]
        ? customerOrderAddon["1"]
        : customerOrderAddon["6"];
      Object.values(customerOrderAddon_tel).map(
        ({ pa_id, pam_id, coa_qty }) => {
          return (total +=
            parseInt(coa_qty) *
            parseInt(productAddonMatrixs[pa_id][pam_id].pam_value));
        }
      );
    }

    total /= 60;
    return `${Intl.NumberFormat().format(total)}`;
  };

  // NOTES

  const renderNotesTelephone = () => {
    let isEmpty = true;
    let telNote = Object.values(customerOrderTelephone || {}).map(
      ({ cot_city, cot_telephone }, idx) => {
        if (cot_telephone.length !== 0 && cot_city !== "0") {
          isEmpty = false;
          return (
            <span key={`span-${idx}`}>{`${
              idx !== 0 ? "," : ""
            } ${cot_city} ( ${cot_telephone.join(", ")} ) `}</span>
          );
        }
      }
    );
    if (isEmpty) return "Nomor belum dipilih";
    return telNote;
  };

  const countTelAddon = () => {
    let total = 0;
    if (customerOrderAddon?.["2"] || customerOrderAddon?.["7"]) {
      const customerOrderAddon_tel = customerOrderAddon["2"]
        ? customerOrderAddon["2"]
        : customerOrderAddon["7"];
      Object.values(customerOrderAddon_tel).map(
        ({ pa_id, pam_id, coa_qty }) => {
          return (
            (total +=
              parseInt(coa_qty) *
              parseInt(productAddonMatrixs?.[pa_id]?.[pam_id]?.pam_value)) || 0
          );
        }
      );
    }

    if (orderType !== "addon") {
      total += parseInt(customerOrderPackage.p_name_telnumber);
    }

    if (customerOrderTelephone) {
      Object.values(customerOrderTelephone).map(
        ({ cot_telephone }) => (total -= cot_telephone.length)
      );
    }

    return total;
  };

  const [noteList, setNoteList] = React.useState([]);

  const renderNoteList = () => {
    let noteArr = [];
    noteArr.push(
      `Masa berlangganan ${(co_mo_subs || 1) * 30} hari setelah pembayaran`
    );
    if (orderType !== "addon" || parseInt(countCreditMinute())) {
      const starCount = noteArr.push(
        `Setara dengan total durasi bicara ${countCreditMinute()} menit ke Indonesia`
      );
      setCreditNoteStar(starCount);
    }
    if (orderType !== "addon" || countTelAddon()) {
      const starCount = noteArr.push(renderNotesTelephone());
      setTelNoteStar(starCount);
    }
    return noteArr;
  };

  React.useEffect(() => setNoteList(renderNoteList), [co_mo_subs]);

  return (
    <div className="OrderTable">
      <div className="table-responsive-lg bg-white">
        <div className="form-title">Ringkasan Pemesanan</div>
        <table className="table" style={{ border: "1px solid #dee2e6" }}>
          <thead
            style={{
              backgroundColor: "#03933e",
              color: "white",
              border: "none",
              padding: "9px",
              textAlign: "center",
            }}
          >
            <tr>
              <th className="text-center">No.</th>
              <th className="text-center">Item</th>
              <th className="text-center">Jumlah</th>
              <th className="text-center">Harga Satuan (IDR)</th>
              <th className="text-center">Total Harga (IDR)</th>
            </tr>
          </thead>

          <tbody>
            {/* PRODUCT ROW - HARDCODE */}
            {productRow && (
              <tr>
                <td className="text-center">1.</td>
                <td>
                  {`${products[customerOrderProduct].p_name} - ${productRow.p_name}`}
                  <ul>
                    <li>{productRow.p_name_trunknum} Trunk Line</li>
                    <li>
                      {`${Intl.NumberFormat().format(
                        productRow.p_name_talkcredit
                      )} Talk Credits`}
                      {co_mo_subs &&
                        co_mo_subs > 1 &&
                        ` + Bonus ${Intl.NumberFormat().format(
                          productRow.bonus_credit
                        )}`}{" "}
                      {"*".repeat(creditNoteStar)}
                    </li>
                    <li>
                      {productRow.p_name_telnumber} Nomor Telepon{" "}
                      {"*".repeat(telNoteStar)}
                    </li>
                    {productRow.p_name_maxsub && (
                      <li>{productRow.p_name_maxsub} Sub Accounts</li>
                    )}
                  </ul>
                </td>
                <td className="text-center">1</td>
                <td className="text-center">
                  {Intl.NumberFormat().format(
                    productRow.p_name_monthlysubscribe
                  )}
                </td>
                <td className="text-end">
                  {Intl.NumberFormat().format(
                    productRow.p_name_monthlysubscribe
                  )}
                </td>
              </tr>
            )}
            {/* ADDON ROWS */}
            {addonRow &&
              addonRow.map(
                ({ coa_qty, pa_name, pam_value, pam_price, noteStar }, idx) => (
                  <tr key={`order-${pa_name}-${pam_value}`}>
                    <td className="text-center">
                      {idx + (productRow ? 2 : 1)}.
                    </td>
                    <td>{`${pa_name}: ${Intl.NumberFormat().format(
                      pam_value
                    )} ${noteStar}`}</td>
                    <td className="text-center">
                      {Intl.NumberFormat().format(coa_qty)}
                    </td>
                    <td className="text-center">
                      {Intl.NumberFormat().format(pam_price)}
                    </td>
                    <td className="text-end">
                      {Intl.NumberFormat().format(pam_price * coa_qty)}
                    </td>
                  </tr>
                )
              )}

            {/* NOTES - HARDCODE*/}
            <tr>
              <td colSpan="1" rowSpan="3"></td>
              <td
                colSpan="1"
                rowSpan="3"
                style={{ fontSize: "0.8rem", opacity: "0.7" }}
              >
                <ol className="ol-star">
                  {noteList.map((content, idx) => (
                    <li key={`note-${idx}`}>{content}</li>
                  ))}
                </ol>
              </td>
              <td colSpan="1" rowSpan="3"></td>
              <td className="text-center" style={lightOrangeTdStyle}>
                Total per Bulan
              </td>
              <td className="text-end" style={lightOrangeTdStyle}>
                {totals.totalPerMonth}
              </td>
            </tr>

            <tr style={lightOrangeTdStyle}>
              <td className="text-center">Total {co_mo_subs || 1} Bulan *</td>
              <td className="text-end">{totals.totalAllMonths}</td>
            </tr>

            <tr style={lightOrangeTdStyle}>
              <td className="text-center">PPN 10%</td>
              <td className="text-end">{totals.ppn}</td>
            </tr>

            <tr
              style={{
                backgroundColor: "#e7621b",
                color: "#fff",
                fontWeight: "600",
                fontSize: "1.1rem",
              }}
            >
              <td colSpan="3"></td>
              <td colSpan="1" className="text-center">
                Total Harga
              </td>
              <td className="text-end">Rp. {totals.finalTotal}</td>
            </tr>
          </tbody>
        </table>
        {navComponent || ""}
      </div>
    </div>
  );
}

const lightOrangeTdStyle = {
  backgroundColor: "#ff8848",
  color: "#fff",
};
