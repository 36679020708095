import React from "react";
import { userLogin, userLoginCookie, userLogout } from "api";

const initialState = { isAuth: false };

const UserContext = React.createContext(initialState);

const UserContextProvider = ({ children }) => {
  const [userState, userDispatch] = React.useReducer((state, action) => {
    const { type, payload } = action;
    switch (type) {
      case "LOADING":
        return { ...state, loading: true };
      case "AUTH_SUCCESS":
        return { ...payload, isAuth: true, loading: false };
      case "AUTH_FAILED":
        return { isAuth: false, loading: false };
      case "AUTH_LOGOUT":
        return { isAuth: false, loading: false };
      default:
        return state;
    }
  }, initialState);

  async function login(email, password, handleRedirect = () => {}) {
    userDispatch({ type: "LOADING" });
    await userLogin(email, password)
      .then((data) => {
        userDispatch({ type: "AUTH_SUCCESS", payload: data });
        handleRedirect();
      })
      .catch(() => {
        userDispatch({ type: "AUTH_FAILED" });
        return alert("Email atau Password Salah!");
      });
  }

  async function fetchUser() {
    userDispatch({ type: "LOADING" });
    await userLoginCookie()
      .then((data) => userDispatch({ type: "AUTH_SUCCESS", payload: data }))
      .catch(() => {
        userDispatch({ type: "AUTH_FAILED" });
      });
  }

  async function logout() {
    userDispatch({ type: "LOADING" });
    await userLogout();

    userDispatch({ type: "AUTH_LOGOUT" });
  }

  return (
    <UserContext.Provider
      value={{ userState, userDispatch, login, logout, fetchUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
