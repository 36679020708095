import React from "react";

// STEO BUTTONS COMPONENT FOR FORM STEPPER

export default function StepButtons({
  next = false,
  prev = false,
  nextDisabled = false,
  prevDisabled = false,
  nextLabel = "Berikutnya",
  prevLabel = "Sebelumnya",
  float = "right",
}) {
  const btnStyle = {
    marginRight: 6 + "px",
    padding: "auto" + 1 + "em",
    float,
  };

  const handleClick = (evt, func) => {
    evt.preventDefault();
    func();
  };

  return (
    <div
      className="StepButtons"
      style={{
        margin: "1em 0",
        position: "relative",
        overflow: "hidden",
        height: "100%",
      }}
    >
      {next && (
        <button
          className="btn btn-success"
          onClick={(evt) => handleClick(evt, next)}
          disabled={nextDisabled}
          style={btnStyle}
        >
          {nextLabel}
        </button>
      )}
      {prev && (
        <button
          className="btn btn-warning"
          onClick={(evt) => handleClick(evt, prev)}
          disabled={prevDisabled}
          style={btnStyle}
        >
          {prevLabel}
        </button>
      )}
    </div>
  );
}
