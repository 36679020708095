import axios from "axios";
import https from "https";

const agent = new https.Agent({
  rejectUnauthorized: false,
});

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

const baseAxios = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  httpsAgent: agent,
  headers: {
    "Content-Type": "application/json",
  },
});

export function getURL(url) {
  return baseAxios.request({
    method: "GET",
    url: `${API_URL}${url}`,
  });
}

export function postURL(url, params = {}) {
  return baseAxios.request({
    method: "POST",
    url: `${API_URL}${url}`,
    params,
  });
}
