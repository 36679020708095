import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/voxnet-logo-white-no-tagline.png";
import { UserContext } from "context";
import { useHistory } from "react-router";

export default function Header() {
  const { userState, logout } = React.useContext(UserContext);
  const { isAuth, cc_name } = userState;
  const history = useHistory();

  const handleLogout = async () => {
    await logout().then(() => window.location.reload());
    history.push("/");
  };

  return (
    <div
      className="Header sticky-top"
      style={{
        position: "sticky",
        width: 100 + "%",
        backgroundColor: "#03933e",
        color: "white",
      }}
    >
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} alt="voxnet-logo" style={{ height: 3.5 + "rem" }} />
          </NavLink>
          <span className="float-end">
            {isAuth ? (
              <div className="dropdown">
                <div
                  className="dropdown-toggle"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {cc_name || "Nama"}
                </div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink
                      to="/dashboard"
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <div
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={handleLogout}
                    >
                      Logout
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              "Self-Service Website"
            )}
          </span>
        </div>
      </nav>
    </div>
  );
}
