import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router";
import OrderTable from "components/forms/partials/OrderTable";
import SubsDuration from "components/forms/partials/SubsDuration";
import StepButtons from "components/forms/partials/subpartials/StepButtons";
import AccountFieldContainer from "components/forms/partials/AccountFieldContainer";
import AddressContainer from "components/forms/partials/AddressContainer";
import Checkout from "components/forms/partials/Checkout";
import { RegisterContext, ProductContext } from "context";

export default function AccountForm({ previousStep, isActive }) {
  const { regisState, submitForm } = useContext(RegisterContext);
  const { prodState } = useContext(ProductContext);
  const { orderType } = regisState;
  const { productAddonMatrixs } = prodState;

  const history = useHistory();

  useEffect(() => {
    if (isActive) window.scrollTo(0, 0);
  }, [isActive]);

  const handleSubmit = async (evt) => {
    function initialization() {
      evt.preventDefault();
      evt.stopPropagation();
      var form = document.querySelector(".needs-validation");
      var fieldsToValidate = document.querySelectorAll(".required", ".unique");
      return { form, fieldsToValidate };
    }

    function checkInvalidField(fieldsToValidate) {
      let firstInvalidInput = null;
      fieldsToValidate.forEach((field) => {
        field.classList.add("was-validated");
        const child = field.querySelector("input");
        if (child.matches(":invalid") && !firstInvalidInput)
          firstInvalidInput = field;
      });
      if (firstInvalidInput) {
        const offsetY = 170;
        var toY =
          firstInvalidInput.getBoundingClientRect().top +
          window.scrollY -
          offsetY;
        return window.scrollTo(0, toY);
      }
    }

    function handleRedirect() {
      history.push({
        pathname: "/submit-success",
        state: {
          title: "Submit Sukses",
          notifMessage: "Permintaan sudah diterima",
          notifSubMessage: "Detail Pembayaran Sudah Terkirim ke Email Anda",
          buttonLabel:
            orderType === "register"
              ? "Kembali ke Home"
              : "Kembali ke Dashboard",
          redirectTo: orderType === "register" ? "/" : "/dashboard",
        },
      });
    }

    var { form, fieldsToValidate } = initialization();
    if (orderType === "register" && !form.checkValidity()) {
      return checkInvalidField(fieldsToValidate);
    } else {
      submitForm(regisState, productAddonMatrixs, handleRedirect);
    }
  };

  return (
    <div className="AccountForm">
      <form className="needs-validation" noValidate onSubmit={handleSubmit}>
        {orderType !== "register" && (
          <div className="form-title bg-white">
            {orderType === "extend"
              ? "Perpanjang Paket"
              : orderType === "addon"
              ? "Tambah Addon"
              : ""}
          </div>
        )}
        {/* ORDER SUMMARY TABLE */}
        <OrderTable
          navComponent={
            <StepButtons
              prev={previousStep}
              prevLabel="Ubah Pesanan"
              float="left"
            />
          }
        />

        {/* SUBSCRIPTION DURATION */}
        {orderType !== "addon" && (
          <SubsDuration
            isActive={isActive}
            redirectTo={
              orderType === "register"
                ? "/register"
                : "/dashbaord/extend-subscription"
            }
          />
        )}

        {/* ACCOUNT INFORMATION */}
        {orderType === "register" && <AccountFieldContainer />}

        {/* COMPANY INFORMATION AND BILLING */}
        {orderType === "register" && <AddressContainer />}
        {/* CHECKOUT AND T&C */}
        <Checkout />
      </form>
    </div>
  );
}
