import React from "react";
import {
  fetchProduct,
  fetchPackage,
  fetchAddon,
  fetchProductBonus,
  fetchProductDIDNumberArea,
} from "api";

const initialState = { product: null, loading: false };

const ProductContext = React.createContext(initialState);

const ProductContextProvider = ({ children }) => {
  const [prodState, prodDispatch] = React.useReducer((state, action) => {
    const { type, payload } = action;
    switch (type) {
      case "LOADING":
        return { loading: true };
      case "STORE_DATA":
        return { ...state, ...payload, loading: false };
      case "FETCH_ERROR":
        return { ...state, loading: false };
      case "RESET_DATA":
        return {};
      default:
        return state;
    }
  }, initialState);

  const handleRedirect = (history, redirectTo = "/") => {
    prodDispatch({ type: "FETCH_ERROR" });
    history.push({
      pathname: "/error",
      state: {
        notifMessage: "Gagal Menarik Data!",
        buttonLabel: "Coba Lagi",
        redirectTo,
      },
    });
  };

  async function fetchAllProduct(history, redirectTo) {
    prodDispatch({ type: "LOADING" });
    await fetchProduct()
      .then((data) => prodDispatch({ type: "STORE_DATA", payload: data }))
      .catch(() => handleRedirect(history, redirectTo));
    await fetchPackage()
      .then((data) => prodDispatch({ type: "STORE_DATA", payload: data }))
      .catch(() => handleRedirect(history, redirectTo));
    await fetchAddon()
      .then((data) => prodDispatch({ type: "STORE_DATA", payload: data }))
      .catch(() => handleRedirect(history, redirectTo));
    await fetchProductBonus()
      .then((data) => prodDispatch({ type: "STORE_DATA", payload: data }))
      .catch(() => handleRedirect(history, redirectTo));
    await fetchProductDIDNumberArea()
      .then((data) => prodDispatch({ type: "STORE_DATA", payload: data }))
      .catch(() => handleRedirect(history, redirectTo));
  }

  return (
    <ProductContext.Provider
      value={{ prodState, prodDispatch, fetchAllProduct }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductContextProvider };
