import React from "react";
import StepWizard from "react-step-wizard";

import { Beforeunload } from "react-beforeunload";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import ProductForm from "components/forms/register/ProductForm";
import PackageForm from "components/forms/register/PackageForm";
import AccountForm from "components/forms/register/AccountForm";
import { ProductContext, RegisterContext } from "context";

export default function Register() {
  const { prodState, fetchAllProduct } = React.useContext(ProductContext);
  const { handleChange, regisState } = React.useContext(RegisterContext);

  const history = useHistory();

  React.useEffect(() => {
    if (!prodState.products) fetchAllProduct(history, "/register");
    handleChange({ orderType: "register" });
  }, []);

  return (
    <Beforeunload onBeforeunload={(evt) => evt.preventDefault()}>
      <div
        className="Register"
        style={{
          maxWidth: "63rem",
          margin: "1rem auto",
        }}
      >
        <Helmet>
          <title>Voxnet | Register</title>
        </Helmet>
        <StepWizard isLazyMount={true}>
          <ProductForm />
          <PackageForm />
          <AccountForm />
        </StepWizard>
      </div>
    </Beforeunload>
  );
}
