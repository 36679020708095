import React from "react";
import { fetchProductDIDNumber } from "api";
import { RegisterContext, ProductContext } from "context";
import Select from "react-select";

export default function DIDNumberOption({
  cot_city = 0,
  cot_telephone = [],
  remainingTel = Infinity,
}) {
  const { regisState, handleChange } = React.useContext(RegisterContext);
  const { prodState } = React.useContext(ProductContext);

  const { customerOrderTelephone = {} } = regisState;
  const { productDIDNumberAreas = [] } = prodState;

  const [numberList, setNumberList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      if (cot_city !== "0") {
        setLoading(true);
        await fetchProductDIDNumber(cot_city).then((data) =>
          setNumberList(data)
        );
        setLoading(false);
      }
    }
    fetchData();
  }, [cot_city]);

  const handleChangeTel = (didNumbers) => {
    let tempArr = [];
    let newObj = customerOrderTelephone;
    didNumbers.forEach(({ value }) => tempArr.push(value));

    newObj[cot_city].cot_telephone = tempArr;

    handleChange({ customerOrderTelephone: newObj });
  };

  const handleDeleteCity = (cot_city) => {
    if (Object.keys(customerOrderTelephone).length <= 1) {
      return handleChange({
        customerOrderTelephone: {
          0: { cot_city: "0", cot_telephone: [] },
        },
      });
    }
    let newObj = customerOrderTelephone;
    delete newObj[cot_city];
    handleChange({ customerOrderTelephone: newObj });
  };

  const handleChangeCity = (oldCity, newCity) => {
    let oldObj = customerOrderTelephone;
    let newObj = {};
    Object.keys(oldObj).forEach((key) => {
      if (key === oldCity) {
        let newPair = { [newCity]: { cot_city: newCity, cot_telephone: [] } };
        newObj = { ...newObj, ...newPair };
      } else {
        newObj = { ...newObj, [key]: { ...oldObj[key] } };
      }
    });
    handleChange({ customerOrderTelephone: newObj });
  };

  const renderNumberOptions = () => {
    if (remainingTel <= 0) return [];

    const options = (numberList || []).reduce((result, { pdn_did_number }) => {
      if (!cot_telephone.includes(pdn_did_number))
        result.push({
          label: pdn_did_number,
          value: pdn_did_number,
        });
      return result;
    }, []);
    return options;
  };

  return (
    <div className="row">
      <div className="mb-3 col-3">
        <Select
          options={productDIDNumberAreas.reduce(
            (result, { pdn_area, pdn_area_code }) => {
              if (!customerOrderTelephone[pdn_area])
                result.push({
                  label: `${pdn_area} - 62${pdn_area_code}`,
                  value: pdn_area,
                });
              return result;
            },
            []
          )}
          onChange={(area) => handleChangeCity(cot_city, area.value)}
          value={cot_city !== "0" ? { label: cot_city, value: cot_city } : ""}
          placeholder="Pilih Kota..."
          isSearchable
        />
      </div>
      <div className="mb-3 col-8" style={{ padding: 0 }}>
        <Select
          isMulti
          options={renderNumberOptions()}
          onChange={handleChangeTel}
          value={cot_telephone.map((didNumber) => {
            return { label: didNumber, value: didNumber };
          })}
          className="basic-multi-select"
          classNamePrefix="select"
          isDisabled={!numberList.length}
          placeholder={
            loading
              ? "loading..."
              : numberList.length || cot_city === "0"
              ? "Pilih Nomor"
              : "Nomor Tidak Tersedia"
          }
        />
      </div>

      <div className="col-1">
        <button
          className="btn btn-outline-danger form-control"
          onClick={() => handleDeleteCity(cot_city)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash-fill"
            viewBox="0 0 16 16"
          >
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
          </svg>
        </button>
      </div>
    </div>
  );
}
