import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, NavLink } from "react-router-dom";

export default function NotifPage({
  title = "Error",
  notifMessage = "Halaman Tidak Ditemukan",
  notifSubMessage = "",
  redirectTo = "/",
  buttonLabel = "Kembali ke Home",
}) {
  const location = useLocation();
  const { state } = location;

  return (
    <div
      className="notifPage bg-white"
      style={{
        textAlign: "center",
        maxWidth: "50em",
        padding: "3em",
        margin: "10em auto",
      }}
    >
      <Helmet>
        <title>{`Voxnet | ${state?.title || title}`}</title>
      </Helmet>
      <div
        style={{
          color: "#e7621b",
          fontSize: "2.5em",
          fontWeight: "600",
          marginBottom: "0.8em",
        }}
      >
        {state?.notifMessage || notifMessage}
      </div>
      <div
        style={{
          color: "#e7621b",
          fontSize: "1.5em",
          fontWeight: "400",
          marginBottom: "0.8em",
        }}
      >
        {state?.notifSubMessage || notifSubMessage}
      </div>
      <NavLink
        to={state?.redirectTo || redirectTo}
        className="btn btn-lg btn-success"
      >
        {state?.buttonLabel || buttonLabel}
      </NavLink>
    </div>
  );
}
