import React from "react";
import { postRegister, postExtend, postAddon } from "api";

const initialState = {};

const RegisterContext = React.createContext(initialState);

const RegisterContextProvider = ({ children }) => {
  const [regisState, regisDispatch] = React.useReducer((state, action) => {
    const { type, payload } = action;
    switch (type) {
      case "LOADING":
        return { ...state, loading: true };
      case "STOP_LOADING":
        return { ...state, loading: false };
      case "HANDLE_CHANGE":
        return { ...state, ...payload };
      case "RESET_ENTRY":
        return { loading: false };
      default:
        return state;
    }
  }, initialState);

  const handleChange = (payload) =>
    regisDispatch({ type: "HANDLE_CHANGE", payload });

  const resetEntry = () => regisDispatch({ type: "RESET_ENTRY" });

  async function submitForm(datas, productAddonMatrixs, handleRedirect) {
    regisDispatch({ type: "LOADING" });
    const { orderType } = datas;

    const postAPI =
      orderType === "register"
        ? postRegister
        : orderType === "extend"
        ? postExtend
        : orderType === "addon"
        ? postAddon
        : null;

    if (postAPI) {
      await postAPI(datas, productAddonMatrixs)
        .then(() => {
          regisDispatch({ type: "STOP_LOADING" });
          regisDispatch({ type: "RESET_ENTRY" });
          return handleRedirect();
        })
        .catch(() => {
          regisDispatch({ type: "STOP_LOADING" });
          return alert("Gagal submit. coba lagi");
        });
    }

    return regisDispatch({ type: "STOP_LOADING" });
  }

  return (
    <RegisterContext.Provider
      value={{
        regisState,
        regisDispatch,
        resetEntry,
        handleChange,
        submitForm,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export { RegisterContext, RegisterContextProvider };
