import react from "react";
import { getURL } from "./base";
import { parseToObject } from "./parser";

// FETCH PRODUCT (OR PACKAGE) from product table
export async function fetchProduct() {
  const products = await getURL(`/Product`)
    .then((res) => parseToObject(res.data.data, "p_id"))
    .catch(() => {
      throw false;
    });
  return { products };
}

export async function fetchPackage(p_id_par = null) {
  const productPackages = await getURL(`/Product/Package/${p_id_par}}`)
    .then((res) => parseToObject(res.data.data, "p_id_par", "p_id"))
    .catch(() => {
      throw false;
    });
  return { productPackages };
}

// FETCH ADDONS from all product_addon_* tables

export async function fetchAddon(p_id = null) {
  // *** CATCH ERROR MUST DEFINED WHEN THIS FUNCTION IS USED *** //
  const data = await getURL(`/Product/Addon/${p_id}`).then((res) => {
    let { productAddons, productAddonItems, productAddonMatrixs } =
      res.data.data;
    productAddons = parseToObject(productAddons, "p_id", "pa_id");
    productAddonItems = parseToObject(productAddonItems, "pa_id", "pai_id");
    productAddonMatrixs = parseToObject(productAddonMatrixs, "pa_id", "pam_id");
    return { productAddons, productAddonItems, productAddonMatrixs };
  });
  return data;
}

// FETCH PRODUCT BONUS
export async function fetchProductBonus() {
  const productBonus = await getURL(`/Product/CreditBonus`)
    .then((res) => parseToObject(res.data.data, "pcb_month"))
    .catch(() => false);
  return { productBonus };
}

// FETCH PRODUCT DID NUMBERS AREA
export async function fetchProductDIDNumberArea() {
  const productDIDNumberAreas = await getURL(`/Product/DIDNumber`)
    .then((res) =>
      res.data.data.sort((a, b) => {
        if (a.pdn_area < b.pdn_area) return -1;
        if (a.pdn_area > b.pdn_area) return 1;
        // names must be equal
        return 0;
      })
    )
    .catch(() => false);
  return { productDIDNumberAreas };
}

// FETCH PRODUCT DID NUMBERS (BASED ON AREA)
export async function fetchProductDIDNumber(pdn_area = "NOT_INCLUDED") {
  const productDIDNumbers = await getURL(`/Product/DIDNumber/${pdn_area}`)
    .then((res) => res.data.data)
    .catch(() => false);
  return productDIDNumbers;
}
