import React from "react";

export default function SuggestionCard({
  cls = "",
  showDetail,
  showCallback,
  sug,
  selected = false,
  handleSelectCallback,
}) {
  const {
    customerOrderPackage,
    customerOrderAddon = {},
    totalPrice = 0,
    summary,
  } = sug;

  return (
    <div
      className={`${cls} mb-3 ${
        selected ? "card choosen choosen-popup" : "card popup"
      }`}
      style={{ margin: "0.3rem", cursor: "pointer" }}
    >
      <div>
        <div className="card-body">
          <h4
            className="card-title"
            style={{ color: "#03933e", fontWeight: "600" }}
          >
            {`Rp. ${Intl.NumberFormat().format(totalPrice)}`}{" "}
            <span className="unit">per bulan</span>
          </h4>
          <div className="row">
            <div onClick={handleSelectCallback}>
              <h5 className="card-title" style={{ color: "#03933e" }}>
                {`Paket ${customerOrderPackage.p_name} ${
                  Object.keys(customerOrderAddon).length > 0 ? "+ Add-Ons" : ""
                }`}
              </h5>
              <div className="card-text" style={detailTextStyle}>
                <span className="val">
                  {" "}
                  {Intl.NumberFormat().format(summary.p_name_telnumber)}{" "}
                </span>
                <span className="unit">Nomor Telepon</span>
                <div className="card-text" style={detailTextStyle}>
                  <span className="val">
                    {" "}
                    {Intl.NumberFormat().format(summary.p_name_trunknum)}{" "}
                  </span>
                  <span className="unit">Trunk Lines</span>
                </div>
                <div className="card-text" style={detailTextStyle}>
                  <span className="val">
                    {" "}
                    {Intl.NumberFormat().format(summary.p_name_talkcredit)}{" "}
                  </span>
                  <span className="unit">Talk Credit</span>
                </div>
                {summary.p_name_maxsub > 0 && (
                  <div className="card-text" style={detailTextStyle}>
                    <span className="val">
                      {" "}
                      {Intl.NumberFormat().format(summary.p_name_maxsub)}{" "}
                    </span>
                    <span className="unit">Sub-Account</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-grid gap-2 mx-auto">
              <div
                className={`btn btn-block ${
                  selected ? "btn-success" : "btn-outline-success"
                }`}
                onClick={handleSelectCallback}
              >
                Pilih
              </div>
            </div>

            {Object.keys(customerOrderAddon).length > 0 && (
              <div
                className="mb-4 mt-4"
                style={{
                  textAlign: "center",
                  color: "#e7621b",
                  cursor: "pointer",
                }}
                onClick={() => showCallback(!showDetail)}
              >
                {`${showDetail ? "Tutup" : "Tampilkan"} Detail`}
              </div>
            )}
            {showDetail && Object.keys(customerOrderAddon).length > 0 && (
              <div onClick={handleSelectCallback}>
                <div>
                  <h5
                    className="card-title"
                    style={{ color: "#e7621b", fontWeight: "600" }}
                  >
                    Paket {customerOrderPackage.p_name}
                  </h5>
                  <div className="card-text mb-3" style={detailTextStyle}>
                    <span className="val">
                      {" "}
                      {Intl.NumberFormat().format(
                        customerOrderPackage.p_name_telnumber
                      )}{" "}
                    </span>
                    <span className="unit">Nomor Telepon</span>
                    <div className="card-text" style={detailTextStyle}>
                      <span className="val">
                        {" "}
                        {Intl.NumberFormat().format(
                          customerOrderPackage.p_name_trunknum
                        )}{" "}
                      </span>
                      <span className="unit">Trunk Lines</span>
                    </div>
                    <div className="card-text" style={detailTextStyle}>
                      <span className="val">
                        {" "}
                        {Intl.NumberFormat().format(
                          customerOrderPackage.p_name_talkcredit
                        )}{" "}
                      </span>
                      <span className="unit">Talk Credit</span>
                    </div>
                    {customerOrderPackage.p_name_maxsub > 0 && (
                      <div className="card-text" style={detailTextStyle}>
                        <span className="val">
                          {" "}
                          {Intl.NumberFormat().format(
                            customerOrderPackage.p_name_maxsub
                          )}{" "}
                        </span>
                        <span className="unit">Sub-Account</span>
                      </div>
                    )}
                  </div>

                  <h5
                    className="card-title"
                    style={{ color: "#e7621b", fontWeight: "600" }}
                  >
                    Addon
                  </h5>

                  {Object.values(customerOrderAddon).map((coa) =>
                    Object.values(coa).map(
                      ({ pa_name, coa_qty, pam_value }, idx) => {
                        if (coa_qty) {
                          return (
                            <div className="card-text" key={`addon-${idx}`}>
                              {`${pa_name.slice(
                                7
                              )} bundled of ${Intl.NumberFormat().format(
                                pam_value
                              )}s: ${coa_qty} unit`}
                            </div>
                          );
                        } else return <div key={`addon-${idx}`}></div>;
                      }
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const detailTextStyle = { margin: "0.4em 0" };
