import React, { useEffect, useContext, useState } from "react";

import { RegisterContext, ProductContext } from "context";
import DIDNumberOption from "components/forms/partials/fields/DIDNumberOption";

export default function ChooseDIDNumber({ navComponent = null }) {
	const { regisState, handleChange } = useContext(RegisterContext);
	const { prodState } = useContext(ProductContext);

	const {
		customerOrderPackage,
		customerOrderAddon,
		customerOrderTelephone = {},
		orderType = "extend",
	} = regisState;

	const { productAddonMatrixs, productDIDNumberAreas = [] } = prodState;

	const [remainingTel, setRemainingTel] = useState(0);

	const handleAddField = () => {
		let newObj = {
			0: { cot_city: "0", cot_telephone: [] },
			...customerOrderTelephone,
		};
		handleChange({ customerOrderTelephone: newObj });
	};

	const countTelAddon = () => {
		let total = 0;
		if (customerOrderAddon?.["2"] || customerOrderAddon?.["7"]) {
			const customerOrderAddon_tel = customerOrderAddon["2"]
				? customerOrderAddon["2"]
				: customerOrderAddon["7"];
			Object.values(customerOrderAddon_tel).map(
				({ pa_id, pam_id, coa_qty }) => {
					return (
						(total +=
							parseInt(coa_qty) *
							parseInt(productAddonMatrixs?.[pa_id]?.[pam_id]?.pam_value)) || 0
					);
				}
			);
		}

		if (orderType !== "addon") {
			total += parseInt(customerOrderPackage.p_name_telnumber);
		}

		if (customerOrderTelephone) {
			Object.values(customerOrderTelephone).map(
				({ cot_telephone }) => (total -= cot_telephone.length)
			);
		}

		return total;
	};

	useEffect(
		() => setRemainingTel(countTelAddon),
		[
			customerOrderPackage,
			customerOrderAddon,
			customerOrderTelephone,
			countTelAddon,
		]
	);

	useEffect(() => {
		if (Object.keys(customerOrderTelephone || {}).length === 0) {
			handleAddField();
		}
	}, []);

	return (
		<div
			className={`ChooseDIDNumber bg-white ${remainingTel !== 0 && "invalid"}`}
			style={remainingTel < 0 ? errorBorderStyle : {}}>
			<div className="form-title">Alokasi Nomor Telepon</div>
			<div
				style={{
					fontSize: "1rem",
					color: "#d63384",
					display: remainingTel > 0 ? "block" : "none",
				}}>
				Harap alokasikan seluruh nomor telepon anda
			</div>
			<div
				className="counter"
				style={{
					color: remainingTel < 0 ? "#d63384" : "#000",
					fontSize: "1.3rem",
					marginBottom: "0.7em",
				}}>
				<span className="unit">anda memiliki sisa</span>
				<span> {remainingTel} </span>
				<span className="unit">nomor</span>
				<span className="unit">
					{remainingTel < 0 && `, kurangi nomor anda.`}
				</span>
			</div>
			<div className="row">
				<div className="col-12">
					{customerOrderTelephone &&
						Object.values(customerOrderTelephone)
							.reverse()
							.map(({ cot_city, cot_telephone }) => (
								<DIDNumberOption
									key={`${cot_city}-choose-did`}
									cot_city={cot_city}
									cot_telephone={cot_telephone}
									remainingTel={remainingTel}
								/>
							))}
					<button
						className="btn btn-success"
						onClick={handleAddField}
						disabled={
							customerOrderTelephone?.[0] ||
							remainingTel <= 0 ||
							Object.keys(customerOrderTelephone).length ===
								productDIDNumberAreas.length
						}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-plus-circle"
							viewBox="0 0 16 16">
							<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
							<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
						</svg>{" "}
						Tambahkan Kota
					</button>
				</div>
			</div>
			{navComponent || ""}
		</div>
	);
}

const errorBorderStyle = {
	borderColor: "#dc3545",
	boxShadow: "0 0 0 0.25rem rgba(220, 53, 69, 0.25)",
};

// useEffect(() => {
//   function handleEmptyTel(cot_city) {
//     let newObj = customerOrderTelephone;
//     newObj[cot_city].cot_telephone = [];
//     handleChange({ customerOrderTelephone: newObj });
//   }

//   if (remainingTel < 0 && customerOrderTelephone) {
//     Object.values(customerOrderTelephone).forEach(({ cot_city }) =>
//       handleEmptyTel(cot_city, 0)
//     );
//   }
// }, [remainingTel]);
