import React, { useContext } from "react";
import NumericUpDown from "components/forms/partials/fields/NumericUpDown";
import { RegisterContext, ProductContext } from "context";

export default function SubsDuration({ isActive = false }) {
  const { regisState, handleChange } = useContext(RegisterContext);
  const { prodState } = useContext(ProductContext);
  let { customerOrderPackage, co_mo_subs } = regisState;
  const { productBonus } = prodState;

  const countBonus = (month = 0) => {
    if (isActive) {
      const perc = productBonus?.[month || 1]?.pcb_credit_bonus || 0;
      const packageCredit = parseInt(
        customerOrderPackage?.p_name_talkcredit || 0
      );
      const bonusCredit = Math.round(packageCredit * perc);
      return bonusCredit || 0;
    }
    return 0;
  };

  const handleOnChange = (co_mo_subs = "1") => {
    handleChange({ co_mo_subs });
  };

  return (
    <div className="SubsDuration bg-white">
      <div className="form-title">Durasi Langganan</div>
      <div className="row">
        <div className="mb3 col-6">
          <div className="row">
            <div className="col-6">
              <NumericUpDown
                value={co_mo_subs ? parseInt(co_mo_subs) : 1}
                name="co_mo_subs"
                min={1}
                max={12}
                onChangeCallback={(val) => handleOnChange(val)}
              />
            </div>
            <div className="col-6">
              <label className="col-form-label">{`Bulan (${
                (parseInt(co_mo_subs) || 1) * 30
              } Hari)`}</label>
            </div>
          </div>
        </div>

        <div className="col-6 col-form-label">
          {`Bonus ${Intl.NumberFormat().format(
            countBonus(co_mo_subs)
          )} Talk Credit per Bulan`}
        </div>
      </div>
    </div>
  );
}
