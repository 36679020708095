import React from "react";

import { NavLink } from "react-router-dom";
import { RegisterContext } from "context";

import Spinner from "components/partials/Spinner";

export default function Checkout() {
  const { regisState, handleChange } = React.useContext(RegisterContext);

  return (
    <div className="bg-white">
      <div className="alert alert-danger" role="alert">
        Detail Pembayaran akan dikirim ke{" "}
        {regisState.cc_name_email || "email akun anda"}
      </div>
      <div className="mb-3 text-center" style={{ margin: "2.5em" }}>
        <input
          name="term_agree"
          className="form-check-input "
          type="checkbox"
          onChange={(evt) => handleChange({ term_agree: evt.target.checked })}
          checked={regisState.term_agree || false}
        />
        <label
          className="form-check-label"
          style={{ color: "#03933e", marginLeft: "0.8em" }}
        >
          Setuju dengan
          <span>
            <NavLink
              to="/terms"
              target="_blank"
              className="link"
              style={{
                textDecoration: "underline",
                color: "#03933e",
                fontWeight: "800",
              }}
            >
              {` syarat dan ketentuan Voxnet`}
            </NavLink>
          </span>
        </label>
      </div>
      <div className="d-grid gap-2 mx-auto">
        <button
          type="submit"
          className="btn btn-lg btn-success"
          disabled={!regisState.term_agree || false}
        >
          {regisState.loading ? (
            <Spinner color="light" thick="0.6em" />
          ) : (
            "Kirim Detail Pembayaran ke Email Saya"
          )}
        </button>
      </div>
    </div>
  );
}
