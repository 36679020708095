import React from "react";
import AddonTable from "components/forms/partials/subpartials/AddonTable";
import { ProductContext, RegisterContext } from "context";

export default function AddonContainer({ navComponent = null }) {
  const { prodState } = React.useContext(ProductContext);
  const { regisState } = React.useContext(RegisterContext);

  const { productAddons, productAddonMatrixs, productAddonItems } = prodState;
  const {
    customerOrderProduct,
    customerOrderAddon,
    orderType = "extend",
  } = regisState;

  const [isEmpty, setIsEmpty] = React.useState(false);

  const checkIsEmpty = () => {
    if (orderType !== "addon") return false;

    let total = 0;
    Object.values(customerOrderAddon || {}).map((coa) =>
      Object.values(coa || {}).map(({ coa_qty }) => {
        return (total += coa_qty || 0);
      })
    );
    return !total;
  };

  React.useEffect(() => setIsEmpty(checkIsEmpty), [customerOrderAddon]);

  return (
    <div className={`AddonContainer bg-white ${isEmpty && "invalid"}`}>
      {orderType === "addon" && isEmpty && (
        <span className="float-end" style={{ color: "red" }}>
          Pilih addon anda
        </span>
      )}

      <div className="form-title">Add-Ons</div>
      <div className="row">
        {customerOrderProduct &&
          Object.values(productAddons[customerOrderProduct]).map(
            ({ pa_id }) => (
              <AddonTable
                cls="col-md-6 col-sm-12"
                key={`aotable-${pa_id}`}
                productAddon={productAddons[customerOrderProduct][pa_id]}
                productAddonMatrix={productAddonMatrixs[pa_id]}
                productAddonItem={productAddonItems[pa_id]}
              />
            )
          )}
      </div>
      {navComponent || ""}
    </div>
  );
}

const errorBorderStyle = {
  borderColor: "#dc3545",
  boxShadow: "0 0 0 0.25rem rgba(220, 53, 69, 0.25)",
};
