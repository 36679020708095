import { postURL, getURL } from "./base";

// UNIQUE FIELD CHECK
export async function searchUnique(url = "", var_name = "var", var_value = "") {
  const isUnique = await getURL(`${url}/${var_name}/${var_value}`)
    .then((res) => res.data.data.is_unique)
    .catch((err) => false);
  return isUnique;
}

// POST REGISTER NEW SUBSCRIPTION
export async function postRegister(
  {
    //Customer Company Datas
    cc_phone = null,
    cc_name = null,
    cc_npwp = null,
    cc_web = null,
    //Customer User Datas
    cu_name_first = null,
    cu_name_last = null,
    cu_name_dsp = null,
    cu_name_email = null,
    cu_name_mobile = null,
    //Customer Address Datas
    address_diff = false,
    address1 = {},
    address2 = {},
    //Customer Order Datas
    co_name,
    co_name_sub,
    co_mo_subs = 1,
    customerOrderPackage = null,
    customerOrderAddon = {},
    customerOrderTelephone = {},
  },
  //Product Addon Matrixs
  productAddonMatrixs = null
) {
  const {
    p_id,
    p_name_avgsubscribe,
    p_name_maxsub = null,
    p_name_maxtalkcredit,
    p_name_monthlysubscribe,
    p_name_talkcredit,
    p_name_talkdur,
    p_name_telnumber,
    p_name_trunknum,
  } = customerOrderPackage;

  // CREATE CUSTOMER ORDER ADDON PARAMS
  const customerOrderAddonParams = [];
  Object.values(customerOrderAddon).forEach((addon) => {
    Object.values(addon).forEach(({ pa_id, pam_id, coa_qty }) => {
      // Check if an addon has coa_qty
      if (parseInt(coa_qty)) {
        customerOrderAddonParams.push({
          pa_id,
          pam_id,
          coa_value: productAddonMatrixs[pa_id][pam_id].pam_value,
          coa_price: productAddonMatrixs[pa_id][pam_id].pam_price,
          coa_qty,
          coa_price_total:
            productAddonMatrixs[pa_id][pam_id].pam_price * coa_qty,
          coa_qty_total: productAddonMatrixs[pa_id][pam_id].pam_value * coa_qty,
        });
      }
    });
  });

  // CREATE CUSTOMER ORDER TELEPHONE PARAMS
  let customerOrderTelephoneParams = [];
  Object.values(customerOrderTelephone).forEach(
    ({ cot_city, cot_telephone }) => {
      // Check if a city has telephone
      if (cot_telephone?.length > 0) {
        cot_telephone.forEach((telephone) => {
          const cot = {
            cot_city,
            cot_telephone: telephone,
          };
          customerOrderTelephoneParams.push(cot);
        });
      }
    }
  );

  // CREATE CUSTOMER ORDER ADDRESS PARAMS
  const addressDefault = {
    ca_attention: "Voxnet",
    ca_address_1:
      "Astoria Building, Jalan TB Simatupang No. 22, Pasar Rebo, Ciracas",
    ca_lr_id: 3172,
    ca_lp_id: 31,
    ca_country: "Indonesia",
    ca_zipcode: 13830,
  };

  const addressCombine = {
    address1,
    address2: address_diff ? address2 : address1,
  };
  let customerAddressParams = [];
  Object.values(addressCombine).forEach((notCheckedAddress, idx) => {
    const {
      ca_attention = addressDefault.ca_attention,
      ca_address_1 = addressDefault.ca_address_1,
      ca_lp_id = addressDefault.ca_lp_id,
      ca_lr_id = addressDefault.ca_lr_id,
      ca_zipcode = addressDefault.ca_zipcode,
      ca_country = addressDefault.ca_country,
      ca_fax,
    } = notCheckedAddress;

    let checkedAddress = {
      ca_attention,
      ca_address_1,
      ca_lp_id,
      ca_lr_id,
      ca_zipcode,
      ca_country,
      ca_fax,
    };

    return customerAddressParams.push({ ...checkedAddress, cac_id: idx + 1 });
  });

  // CREATE CUSTOMER COMPANIES PARAMS
  const customerCompaniesParams = {
    cc_phone,
    cc_name,
    cc_npwp,
    cc_web,
  };
  // CREATE CUSTOMER USER PARAMS
  const customerUserParams = {
    cu_name_first,
    cu_name_last,
    cu_name_dsp,
    cu_name_email,
    cu_name_mobile,
  };
  // CREATE USER PARAMS
  const userParams = {
    nama: cu_name_first,
    username: cu_name_email,
  };
  // CREATE CUSTOMER ORDER PARAMS
  const customerOrderParams = {
    p_id,
    co_mo_subs,
    co_name,
    co_name_sub,
    co_name_telnumber: p_name_telnumber,
    co_name_trunknum: p_name_trunknum,
    co_name_talkcredit: p_name_talkcredit,
    co_name_talkdur: p_name_talkdur,
    co_name_maxsub: p_name_maxsub,
    co_name_maxtalkcredit: p_name_maxtalkcredit,
    co_name_avgsubscribe: p_name_avgsubscribe,
    co_name_monthlysubscribe: p_name_monthlysubscribe,
  };

  // SEND PARAMS TO BUCKETAPI
  await postURL("/User/register", {
    userParams,
    customerCompaniesParams,
    customerAddressParams,
    customerUserParams,
    customerOrderParams,
    customerOrderAddonParams: customerOrderAddonParams?.length
      ? customerOrderAddonParams
      : null,
    customerOrderTelephoneParams: customerOrderTelephoneParams?.length
      ? customerOrderTelephoneParams
      : null,
  }).then((res) => {
    const { co_id } = res.data.data;

    return postURL("/mailersmtp/send_invoice", {
      destination: cu_name_email,
      template: "pi",
      co_id,
    });
  });
}

// POST EXTEND SUBSCRIPTION FORM
export async function postExtend(
  {
    //
    cu_name_email,
    //Customer Order Datas
    cc_id = null,
    co_mo_subs = 1,
    customerOrderPackage = null,
    customerOrderAddon = {},
    customerOrderTelephone = {},
  },
  //Product Addon Matrixs
  productAddonMatrixs = null
) {
  const {
    p_id,
    p_name,
    p_name_maxsub,
    p_name_maxtalkcredit,
    p_name_monthlysubscribe,
    p_name_talkcredit,
    p_name_telnumber,
    p_name_trunknum,
  } = customerOrderPackage;

  const customerOrderParams = {
    p_id,
    co_mo_subs,
    co_name: p_name,
    co_name_telnumber: p_name_telnumber,
    co_name_trunknum: p_name_trunknum,
    co_name_talkcredit: p_name_talkcredit,
    co_name_maxsub: p_name_maxsub,
    co_name_maxtalkcredit: p_name_maxtalkcredit,
    co_name_monthlysubscribe: p_name_monthlysubscribe,
  };

  const customerOrderAddonParams = [];
  Object.values(customerOrderAddon).forEach((addon) => {
    Object.values(addon).forEach(({ pa_id, pam_id, coa_qty }) => {
      if (coa_qty) {
        customerOrderAddonParams.push({
          pa_id,
          pam_id,
          coa_value: productAddonMatrixs[pa_id][pam_id].pam_value,
          coa_price: productAddonMatrixs[pa_id][pam_id].pam_price,
          coa_qty,
          coa_price_total:
            productAddonMatrixs[pa_id][pam_id].pam_price * coa_qty,
          coa_qty_total: productAddonMatrixs[pa_id][pam_id].pam_value * coa_qty,
        });
      }
    });
  });

  let customerOrderTelephoneParams = [];
  Object.values(customerOrderTelephone).forEach(
    ({ cot_city, cot_telephone }) => {
      // Check if a city has telephone
      if (cot_telephone?.length > 0) {
        cot_telephone.forEach((telephone) => {
          const cot = {
            cot_city,
            cot_telephone: telephone,
          };
          customerOrderTelephoneParams.push(cot);
        });
      }
    }
  );

  // NOTE: HANDLE CATCH AT WHERE THIS FUNCTION CALLED
  await postURL("/User/extend", {
    cc_id,
    customerOrderParams,
    customerOrderAddonParams,
    customerOrderTelephoneParams,
  }).then((res) => {
    const { co_id } = res.data.data;

    return postURL("/mailersmtp/send_invoice", {
      destination: cu_name_email,
      template: "pi",
      co_id,
    });
  });
}

// POST ADD ADDON FORM
export async function postAddon(
  { co_id, customerOrderAddon, customerOrderTelephone = {} },
  productAddonMatrixs = null
) {
  const customerOrderAddonParams = [];
  Object.values(customerOrderAddon).forEach((addon) => {
    Object.values(addon).forEach(({ pa_id, pam_id, coa_qty }) => {
      if (coa_qty) {
        customerOrderAddonParams.push({
          pa_id,
          pam_id,
          coa_value: productAddonMatrixs[pa_id][pam_id].pam_value,
          coa_price: productAddonMatrixs[pa_id][pam_id].pam_price,
          coa_qty,
          coa_price_total:
            productAddonMatrixs[pa_id][pam_id].pam_price * coa_qty,
          coa_qty_total: productAddonMatrixs[pa_id][pam_id].pam_value * coa_qty,
        });
      }
    });
  });

  let customerOrderTelephoneParams = [];
  Object.values(customerOrderTelephone).forEach(
    ({ cot_city, cot_telephone }) => {
      // Check if a city has telephone
      if (cot_telephone?.length > 0) {
        cot_telephone.forEach((telephone) => {
          const cot = {
            cot_city,
            cot_telephone: telephone,
          };
          customerOrderTelephoneParams.push(cot);
        });
      }
    }
  );

  // NOTE: HANDLE CATCH AT WHERE THIS FUNCTION CALLED
  await postURL("/User/addon", {
    co_id,
    customerOrderAddonParams,
    customerOrderTelephoneParams,
  });
}
