import React from "react";

export default function Field({
  type = "text",
  name = "",
  label = "Field Label",
  cls = "",
  info = "",
  required = false,
  errorMsg = "Harap diisi",
  uniqueAPI = null,
  disabled = false,
  prefix = null,
  value = "",
  handleChange = () => {},
}) {
  return (
    <div
      className={`
        Field mb-3 
        ${cls}
        ${required ? "required" : ""}
        ${uniqueAPI ? "unique" : ""}
      `}
    >
      {/* <label>
        {label} {required ? <span style={{ color: "#dc3545" }}>*</span> : ""}
      </label> */}
      <small className="form-text text-muted float-end">{info}</small>
      <div className="input-group mb-3">
        {prefix && (
          <span className="input-group-text" id="basic-addon1">
            {prefix}
          </span>
        )}
        <input
          name={name}
          type={type}
          className="form-control"
          style={{ fontSize: "1.1em", padding: "10px" }}
          disabled={disabled}
          autoComplete="off"
          required={required}
          value={value}
          onChange={(evt) => handleChange(name, evt.target.value)}
          placeholder={label}
        />
        <div className="invalid-feedback">{errorMsg}</div>
      </div>
    </div>
  );
}
