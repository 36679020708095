import React, { useContext, useEffect, useState } from "react";
import { RegisterContext } from "context";
import { fetchProvince, fetchRegency } from "api";
import { useIsMounted } from "hooks/useIsMounted";
import Select from "react-select";

export default function SingleSelect({
  isLoading = false,
  isSearchable = true,
  name = "select_name",
  cls = "",
  label = "Field Label",
  info = "",
  stateGroup = null,
  required = false,
  errorMsg = "Harap diisi",
}) {
  const { regisState, handleChange } = useContext(RegisterContext);

  const defaultValue =
    name === "ca_lp_id"
      ? { value: "31", label: "DKI JAKARTA" }
      : name === "ca_lr_id"
      ? { value: "3172", label: "KOTA JAKARTA TIMUR" }
      : { value: "", label: "" };

  const isMounted = useIsMounted();

  const [options, setOptions] = useState([]);

  // it's not too necessary except for emptying regency (Kota/Kabupaten) field (value property of null)
  // whenever different province is selected
  const [selectedOption, setSelectedOption] = useState(null);

  // POPULATE PROVINCE OPTIONS
  useEffect(() => {
    async function fetchData() {
      let data = [];
      data = await fetchProvince();
      if (isMounted.current) return setOptions(data);
    }
    if (name === "ca_lp_id") fetchData();
  }, []);

  // POPULATE REGENCY OPTIONS
  useEffect(() => {
    async function fetchData() {
      if (isMounted.current) setSelectedOption(null);
      let data = [];
      if (regisState[stateGroup]?.["ca_lp_id"]) {
        const provinceID = regisState[stateGroup]["ca_lp_id"];
        data = await fetchRegency(provinceID);
        if (isMounted.current) setOptions(data);
      }
    }
    if (name === "ca_lr_id") fetchData();
  }, [regisState[stateGroup]?.["ca_lp_id"]]);

  // Empty Regency Field and state if difference province is selected
  useEffect(() => {
    if (name === "ca_lr_id" && regisState.ca_lp_id) {
      if (isMounted.current) setSelectedOption(null);
      handleChange({ [name]: null });
    }
  }, [regisState.ca_lp_id, name]);

  const handleOnChange = ({ value, label }) => {
    setSelectedOption({ value, label });
    let tempObj = regisState[stateGroup] || {};
    tempObj[name] = value;
    handleChange({ [stateGroup]: tempObj });
  };

  return (
    <div className={`mb-3 ${cls} ${required ? "required" : ""}`}>
      <label>{label}</label>
      <small className="form-text text-muted float-right">{info}</small>
      <Select
        styles={customStyles}
        className="basic-single required"
        classNamePrefix="form-control"
        placeholder=""
        isDisabled={
          name === "ca_lr_id"
            ? regisState[stateGroup]
              ? !regisState[stateGroup].ca_lp_id
              : true
            : false
        }
        isLoading={isLoading}
        isSearchable={isSearchable}
        name={name}
        noOptionsMessage={() => `${label || "Pilihan"} tidak ditemukan`}
        onChange={handleOnChange}
        value={selectedOption || defaultValue}
        options={options}
      />
      {required && <div className="invalid-feedback">{errorMsg}</div>}
    </div>
  );
}

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
};
