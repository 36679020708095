import React from "react";
import { RegisterContext, ProductContext } from "context";
import Field from "components/forms/partials/fields/Field";
import SuggestionCard from "./subpartials/SuggestionCard";
import useSuggestion from "hooks/useSuggestion";
import { findRenderedComponentWithType } from "react-dom/test-utils";

export default function Suggestion() {
  const { regisState, handleChange } = React.useContext(RegisterContext);
  const { prodState } = React.useContext(ProductContext);
  let { productPackages, productAddons, productAddonMatrixs } = prodState;
  const suggestionAlgo = useSuggestion;

  const {
    customerOrderProduct,
    customerOrderPackage,
    selected_suggestion = null,
    s_p_name_telnumber = 0,
    s_p_name_trunknum = 0,
    s_p_name_talkcredit = 0,
    s_p_name_maxsub = 0,
  } = regisState;

  productPackages = productPackages[customerOrderProduct];
  productAddons = productAddons[customerOrderProduct];

  React.useEffect(
    () => handleChange({ selected_suggestion: null }),
    [
      s_p_name_telnumber,
      s_p_name_trunknum,
      s_p_name_talkcredit,
      s_p_name_maxsub,
    ]
  );

  React.useEffect(() => {
    const selectedSuggestionPackage =
      sugs?.[selected_suggestion?.[0]]?.customerOrderPackage?.p_name;
    const orderedPackage = customerOrderPackage?.p_name;

    if (selected_suggestion && selectedSuggestionPackage !== orderedPackage) {
      return handleChange({
        selected_suggestion: null,
        customerOrderAddon: {},
      });
    }
  }, [customerOrderPackage]);

  const [sugs, setSug] = React.useState(null);

  const [showDetail, setShowDetail] = React.useState(false);

  const createSuggestion = () => {
    const suggestions = suggestionAlgo(
      regisState,
      productPackages,
      productAddons,
      productAddonMatrixs
    );
    setSug(suggestions);
  };

  const handleSelect = (sugName) => {
    const idx = sugName[0];
    const { customerOrderAddon, customerOrderPackage } = sugs[idx];

    return handleChange({
      selected_suggestion: sugName,
      customerOrderAddon,
      customerOrderPackage,
    });
  };

  return (
    <div className="bg-white">
      <div className="form-title">Paket untuk Saya</div>
      <div className="row">
        <Field
          name="s_p_name_telnumber"
          type="number"
          label="Jumlah Nomor Telepon"
          cls="col-lg-6 col-xs-12"
          min={0}
        />
        <Field
          name="s_p_name_trunknum"
          type="number"
          label="Jumlah Trunk"
          cls="col-lg-6 col-xs-12"
          min={0}
        />
        <Field
          name="s_p_name_talkcredit"
          type="number"
          label="Durasi bicara per bulan (detik)"
          cls="col-lg-6 col-xs-12"
          min={0}
        />
        {customerOrderProduct === "1" && (
          <Field
            name="s_p_name_maxsub"
            type="number"
            label="Jumlah Sub Akun"
            cls="col-lg-6 col-xs-12"
            min={0}
          />
        )}
      </div>

      <div className="d-grid gap-2 col-4 col-xs-12 mx-auto">
        <button
          className="btn btn-success mb-4"
          onClick={createSuggestion}
          stytle={{ width: "100%", margin: "auto" }}
        >
          Buat Paket Saya
        </button>
      </div>

      {sugs ? (
        sugs.length > 0 ? (
          <div className="row">
            <div className="form-title">Rekomendasi: </div>
            {sugs.map((sug, idx) => (
              <SuggestionCard
                cls="col"
                key={`${idx}-sug`}
                sug={sug}
                showCallback={() => setShowDetail(!showDetail)}
                showDetail={showDetail}
                selected={selected_suggestion === `${idx}-sug`}
                handleSelectCallback={() => handleSelect(`${idx}-sug`)}
              />
            ))}
          </div>
        ) : (
          <div
            className="text-center mt-4 mb-4"
            style={{ fontSize: "1.5rem", color: "#ff8848", fontWeight: "600" }}
          >
            Rekomendasi Tidak ditemukan!
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
}
