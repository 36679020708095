import React from "react";
import StepWizard from "react-step-wizard";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import Spinner from "components/partials/Spinner";
import { RegisterContext, UserContext, ProductContext } from "context";

import PackageForm from "components/forms/register/PackageForm";
import AccountForm from "components/forms/register/AccountForm";

export default function DashboardForm() {
  const { handleChange } = React.useContext(RegisterContext);
  const { userState } = React.useContext(UserContext);
  const { prodState, fetchAllProduct } = React.useContext(ProductContext);
  const { co_id, orderType } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    if (!userState.customerOrder[co_id]) history.push("/dashboard");

    handleChange({ ...userState.customerOrder[co_id], orderType });
    if (orderType === "addon")
      handleChange({
        customerOrderAddon: {},
        customerOrderTelephone: null,
      });
  }, []);

  const [isFetched, setIsFetced] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      if (!prodState.products) await fetchAllProduct(history, "/dashboard");
      setIsFetced(true);
    }
    fetchData();
  }, []);

  return (
    <div className="DashboardForm">
      <Helmet>
        <title>{`Voxnet | ${
          orderType === "addon" ? "Tambah Addon" : "Perpanjang"
        }`}</title>
      </Helmet>

      <div
        className="FormContainer"
        style={{
          maxWidth: "63rem",
          margin: "1rem auto",
        }}
      >
        {isFetched ? (
          <StepWizard
            initialStep={orderType === "extend" ? 2 : 1}
            isLazyMount={true}
          >
            <PackageForm />
            <AccountForm />
          </StepWizard>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
