import React from "react";

import { RegisterContext, ProductContext } from "context";

import StepButtons from "components/forms/partials/subpartials/StepButtons";
import AddonContainer from "components/forms/partials/AddonContainer";
import PackageContainer from "components/forms/partials/PackageContainer";
import ChooseNumber from "components/forms/partials/ChooseDIDNumber";
import Suggestion from "components/forms/partials/Suggestion";
import Spinner from "components/partials/Spinner";

export default function PackageForm({ isActive, nextStep, previousStep }) {
  // Handle Scroll to Invalid Input Before Move to Next Form
  React.useEffect(() => {
    if (isActive) window.scrollTo(0, 0);
  }, [isActive]);

  const handleNext = () => {
    var formContainer = document.querySelector(".needs-validation");
    formContainer.classList.add("validated");
    var invalidForms = document.querySelectorAll(".needs-validation .invalid");

    if (invalidForms.length <= 0) return nextStep();
    else {
      const offsetY = 120;
      const toY =
        invalidForms[0].getBoundingClientRect().top + window.scrollY - offsetY;

      return window.scrollTo(0, toY);
    }
  };

  // Initialization
  const { prodState } = React.useContext(ProductContext);
  const { regisState } = React.useContext(RegisterContext);
  const { customerOrderPackage, orderType = "register" } = regisState;
  const { loading } = prodState;

  return (
    <div className="PackageForm needs-validation">
      {loading ? (
        <Spinner background={true} />
      ) : (
        <div>
          {orderType !== "addon" && (
            <div>
              <Suggestion />
              <PackageContainer
                navComponent={
                  orderType === "register" && (
                    <StepButtons
                      prev={previousStep}
                      prevLabel="Ganti Produk"
                      float="left"
                    />
                  )
                }
              />
            </div>
          )}

          {customerOrderPackage && isActive && <AddonContainer />}

          {customerOrderPackage && (
            <ChooseNumber
              navComponent={
                <StepButtons
                  next={handleNext}
                  prev={orderType === "register" && previousStep}
                  prevLabel={orderType === "register" && "Ganti Produk"}
                  nextDisabled={!customerOrderPackage}
                />
              }
            />
          )}
        </div>
      )}
    </div>
  );
}
