import { getURL } from "./base";
import { parseToOption } from "./parser";

// FETCH PROVINCES OPTION
export async function fetchProvince() {
  const provinces = await getURL("/Location/Provinces")
    .then((res) => parseToOption(res.data.data))
    .catch((err) => []);
  return provinces;
}

// FETCH REGENCIES OPTION
export async function fetchRegency(provinceID) {
  const regencies = await getURL(`/Location/Regencies/${provinceID}`)
    .then((res) => parseToOption(res.data.data))
    .catch((err) => []);
  return regencies;
}
