import React, { useContext } from "react";
import { RegisterContext } from "context";
import ReactTooltip from "react-tooltip";
import NumericUpDown from "components/forms/partials/fields/NumericUpDown";

export default function AddonTable({
  cls = "",
  productAddon = null,
  productAddonMatrix = null,
  productAddonItem = null,
}) {
  const { regisState, handleChange } = useContext(RegisterContext);

  const {
    customerOrderAddon,
    customerOrderPackage,
    orderType = "register",
  } = regisState;

  const minInput = 0;
  const maxInput = Infinity;

  let {
    p_name_talkcredit,
    p_name_maxsub,
    p_name_telnumber,
    p_name_trunknum,
    p_name_maxtalkcredit,
  } = customerOrderPackage;

  const { pa_id, pa_name, pa_name_description } = productAddon || {};

  const [addonCount, setAddonCount] = React.useState(null);

  const countAddon = () => {
    let total = 0;
    if (customerOrderAddon?.[pa_id]) {
      Object.values(customerOrderAddon[pa_id]).map(({ pam_id, coa_qty }) => {
        return (total +=
          parseInt(coa_qty) * parseInt(productAddonMatrix[pam_id].pam_value));
      });
    }

    if (orderType !== "addon") {
      if (pa_id === "1" || pa_id === "6") total += parseInt(p_name_talkcredit); // Talk Credit
      if (pa_id === "2" || pa_id === "7") total += parseInt(p_name_telnumber); // Telephone number
      if (pa_id === "3" || pa_id === "8") total += parseInt(p_name_trunknum); // Trunk-Lines
      if (pa_id === "5") total += parseInt(p_name_maxsub); // Sub-accounts
    }

    return total;
  };

  React.useEffect(
    () => setAddonCount(countAddon()),
    [customerOrderAddon, customerOrderPackage]
  );

  const handleOnChange = async (pa_id, pam_id, coa_qty) => {
    let temp = customerOrderAddon || {};
    if (!temp[pa_id]) temp[pa_id] = {};
    if (!temp[pa_id][pam_id]) temp[pa_id][pam_id] = {};
    temp[pa_id][pam_id] = { pa_id, pam_id, coa_qty };

    await handleChange({ customerOrderAddon: { ...temp } });
  };

  // COUNT ADDON LIMIT

  const updateAddonLimit = () => {
    let limit = Infinity;
    if (pa_id === "1" || pa_id === "6") {
      limit = parseInt(p_name_maxtalkcredit);
    }
    return limit;
  };

  const [addonLimit, setAddonLimit] = React.useState(updateAddonLimit());

  React.useEffect(
    () => setAddonLimit(updateAddonLimit()),
    [customerOrderPackage]
  );

  return (
    <div className={`AddonTable ${cls}`} style={{ marginBottom: "1.5rem" }}>
      {addonCount > addonLimit && (
        <div style={{ color: "red" }}>Talk Credit Melebihi Limit!</div>
      )}
      <div
        className={`card ${addonCount > addonLimit && "invalid"}`}
        style={addonCount > addonLimit ? errorBorderStyle : {}}
      >
        <div className="card-header" style={{ padding: "0.8em" }}>
          {pa_name}
          <div
            className="float-end"
            style={{ fontSize: "0.85rem", marginTop: "0.25rem" }}
          >
            {`${Intl.NumberFormat().format(addonCount)} ${
              addonLimit !== Infinity
                ? `/ ${Intl.NumberFormat().format(addonLimit)}`
                : ""
            } ${Object.values(productAddonItem)[0].pa_name}`}
          </div>
          <span
            style={{ marginLeft: "8px" }}
            data-tip={pa_name_description}
            data-effect="solid"
            data-for="infoTooltip"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-question-circle-fill"
              viewBox="0 0 17 17"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
            </svg>
          </span>
        </div>

        <div className="card-body">
          <table className="table">
            <tbody>
              {productAddonMatrix &&
                Object.values(productAddonMatrix).map(
                  ({ pa_id, pam_id, pam_value, pam_price }) => (
                    <tr key={`row-${pam_id}`} className="align-middle">
                      <td>
                        <span className="val">
                          {Intl.NumberFormat().format(pam_value)}{" "}
                        </span>
                        <span className="unit">
                          {/* Addon Unit Stored in productAddonItem */}
                          {/* addon unit mixed up with price unit */}
                          {/* every addon unit has odd (ganjil) pai_id. we use it for filtered it from price unit */}
                          {Object.values(productAddonItem).map(
                            ({ pai_id, pa_name }) => {
                              if (parseInt(pai_id) % 2 === 1)
                                return (
                                  <span key={`unit-${pai_id}`}>{pa_name}</span>
                                );
                              return <span key={`unit-${pai_id}-null`}></span>;
                            }
                          )}
                        </span>
                      </td>
                      <td>
                        <span className="unit">Rp.</span>{" "}
                        {Intl.NumberFormat().format(pam_price)}
                      </td>
                      <td>
                        <NumericUpDown
                          style={{ width: "6rem" }}
                          name={pam_id}
                          value={
                            customerOrderAddon?.[pa_id]?.[pam_id]?.coa_qty ||
                            minInput
                          }
                          type="number"
                          min={minInput}
                          max={
                            addonCount >= addonLimit
                              ? customerOrderAddon?.[pa_id]?.[pam_id]
                                  ?.coa_qty || maxInput
                              : maxInput
                          }
                          onChangeCallback={(val) =>
                            handleOnChange(pa_id, pam_id, val)
                          }
                          onKeyPress={(evt) => evt.preventDefault()}
                        />
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
      <ReactTooltip id="infoTooltip" />
    </div>
  );
}

const errorBorderStyle = {
  borderColor: "#dc3545",
  boxShadow: "0 0 0 0.25rem rgba(220, 53, 69, 0.25)",
};
