// Parsed returned Array from GET PRODUCT API
// to Object/nested Object
export function parseToObject(
  allDatas,
  firstLevelKey,
  secondLevelKey = null,
  thirdLevelKey = null
) {
  let parsedData = {};
  allDatas.forEach((d) => {
    if (!parsedData[d[firstLevelKey]]) {
      parsedData[d[firstLevelKey]] = {};
    }
    if (!parsedData[d[firstLevelKey]][d[secondLevelKey]]) {
      parsedData[d[firstLevelKey]][d[secondLevelKey]] = {};
    }

    if (thirdLevelKey) {
      parsedData[d[firstLevelKey]][d[secondLevelKey]][d[thirdLevelKey]] = {
        ...d,
      };
    } else if (secondLevelKey) {
      parsedData[d[firstLevelKey]][d[secondLevelKey]] = { ...d };
    } else parsedData[d[firstLevelKey]] = { ...d };
  });
  return parsedData;
}

export function parseToObjectTelephone(
  allDatas,
  firstLevelKey,
  secondLevelKey,
  dataToArray
) {
  let parsedData = {};
  allDatas.forEach((d) => {
    // Initialize not exist level object
    if (!parsedData[d[firstLevelKey]]) {
      parsedData[d[firstLevelKey]] = {};
    }
    if (!parsedData[d[firstLevelKey]][d[secondLevelKey]]) {
      parsedData[d[firstLevelKey]][d[secondLevelKey]] = {};
    }
    // Initialize not exist array key
    if (!parsedData[d[firstLevelKey]][d[secondLevelKey]][dataToArray]) {
      parsedData[d[firstLevelKey]][d[secondLevelKey]][dataToArray] = [];
    }
    // populate object and dataToArray's array
    let telTemp = parsedData[d[firstLevelKey]][d[secondLevelKey]][dataToArray];
    telTemp.push(d.cot_telephone);

    parsedData[d[firstLevelKey]][d[secondLevelKey]] = { ...d };

    parsedData[d[firstLevelKey]][d[secondLevelKey]][dataToArray] = telTemp;
  });

  return parsedData;
}

// PARSE TO ARRAY OF OBJECT FOR REACT-SELECT'S OPTION PROPS
export function parseToOption(allDatas, valueVal = "id", labelVal = "name") {
  let parsedData = [];
  allDatas.forEach((d) =>
    parsedData.push({ value: d[valueVal], label: d[labelVal] })
  );
  return parsedData;
}
