import React from "react";

export default function Spinner({
  color = "dark",
  thick = "1em",
  background = false,
  style = {},
}) {
  return (
    <div className={background ? "bg-white" : ""} style={style}>
      <div
        className="Spinner"
        style={{ textAlign: "center", width: "100%", fontSize: thick }}
      >
        <div className={`spinner-border text-${color}`} role="status"></div>
      </div>
    </div>
  );
}
