import React from 'react'
import { Helmet } from 'react-helmet';

export default function Terms() {
  return (
    <div className='Terms'>
      <Helmet>
        <title>Voxnet | Terms</title>
      </Helmet>
      <div className='form-title'>Ketentuan Umum</div>

      <ol className='common-ol'>
        <li>
          <span className='list-title'>PENJELASAN PENGERTIAN</span>
          <ol className='common-ol'>
            <li>PT Lima Satu Tiga Global Tel-Access adalah selaku pemilik dan penyedia produk layanan dari Brand/Merk Voxnet, yang bekerjasama dengan operator/penyedia jasa Telekomunikasi di Indonesia.</li>
            <li>Voxnet adalah produk layanan dengan berbagai macam sistem telepon dan pesan dalam konsep virtual bagi semua jenis komunikasi bisnis berupa layanan Cloud PBX dan Cloud SIP-Trunks serta yang menciptakan contact center atau multi branches communication yang hadir dengan nomor telepon profesional dan flexibel untuk untuk menghubungkan ke laptop, telepon meja dan telepon pintar, dengan tingkat komunikasi yang tinggi yang hanya dapat diakses oleh pengguna melalui jaringan internet.</li>
            <li>Pengguna adalah perorangan atas nama pribadi, organisasi atau badan hukum lainnya yang mendaftar dan yang telah menandatangani formulir pendaftaran dan berlangganan yang disediakan oleh Voxnet.</li>
            <li>Pengguna bertanggung jawab sepenuhnya atas ketentuan-ketentuan yang diatur dalan perjanjian berlangganan ini, termasuk penyediaan jaringan internet sendiri oleh Pengguna.</li>
            <li>Perjanjian berarti syarat dan ketentuan ini.</li>
            <li>Kecuali ditentukan lain pada pasal &ndash; pasal lain dalam perjanjian ini, Para Pihak sepakatmendefinisikan pengertian &ndash; pengertian sebagai berikut :

              <ol className='lower-alpha'>
                <li className='alpha'><strong>Biaya Bulanan/Berlangganan</strong> adalah biaya yang dikenakan kepada pengguna atas pemanfaatan Jasa Berlangganan sesuai dengan perjanjian yang telah disepakati.</li>
                <li className='alpha'><strong>Fraud</strong> adalah berbagai tindakan kecurangan, penipuan atau penggelapan dalam penggunaan jasa layanan yang diberikan oleh Voxnet, sebagaimana dimaksud dalam perjanjian ini, baik disengaja maupun tidak disengaja ataupun karena kelalaian yang dilakukan oleh Pihak yang satu kepada Pihak Lainnya dan /atau Pihak Ketiga dengan tujuan menghindari biaya jasa dan /atau demi keuntungan sendiri bagi Pihak yang melakukan <em>Fraud</em> dalam rangka pelaksanaan perjanjian ini.</li>
                <li className='alpha'><strong>Koneksi</strong> adalah keterhubungan antara perangkat / system Voxnet dengan pengguna.</li>
                <li className='alpha'><strong>Link</strong> Koneksi adalah link yang digunakan untuk penyaluran jaringan koneksi yang menghubungkan jaringan/system Voxnet dengan jaringan/system Pengguna.</li>
                <li className='alpha'><strong>Routing</strong> adalah proses penentuan dan penggunaan <em>route</em>berdasarkan aturan tertentu, untuk penyalurkan pesan atau untuk membangun hubungan.</li>
                <li className='alpha'><strong>SIP <em>(Session Initation Protocol)</em> Trunk</strong> adalah sebuah protocol komunikasi standard untuk suara dan video dalam unified Communication untuk solusi di jaringan sebuah data.</li>
              </ol>

            </li>
          </ol>
        </li>
        <p className='sub-title'>HAK, KEWAJIBAN DAN LARANGAN BAGI PENGGUNA</p>
        <li>
          <span className='list-title'>HAK PENGGUNA</span>
          <ol className='common-ol'>
            <li>Pengguna berhak mendapatkan pelayanan yang baik serta informasi mengenai produk dan tarif.</li>
            <li>Pengguna berhak untuk memanfaatkan layanan produk jaringan telekomunikasi sesuai dengan fasilitas jenis produk yang dipilih.</li>
            <li>Pengguna berhak untuk mendapatkan perlindungan kerahasiaan data pelanggan dan data komunikasi.</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>KEWAJIBAN PENGGUNA</span>
          <ol className='common-ol'>
            <li>Pengguna memiliki itikad baik dalam melaksanakan perjanjian ini termasuk dalam memberikan informasi identias yang valid.</li>
            <li>Pengguna wajib melakukan pembayaran biaya berlangganan sesuai dengan jenis produk yang dipilih dengan nominal yang disepakati dalam formulir berlangganan sebelum berakhirnya batas waktu pembayaran.        </li>
            <li>Pengguna wajib melaporkan kepada Voxnet jika adanya gangguan pada jaringan.</li>
            <li>Pengguna wajib memberitahukan kepada Voxnet apabila bermaksud untuk tidak memperpanjangan periode masa berlangganan dan/atau berhenti berlangganan, pemberitahuan dapat disampaikan kepada Voxnet melalui telepon 021-30008686 atau melalui email info@voxnet.id pada hari dan jam kerja.</li>
            <li>Pengguna wajib memenuhi dan tunduk pada ketentuan dan peraturan perundang-undangan yang berlaku di wilayah Republik Indonesia mengenai penggunaan atau pemanfaatan jasa komunikasi ini.</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>LARANGAN PENGGUNA</span>
          <ol className='common-ol'>
            <li>Pengguna dilarang untuk melakukan pemindahan dan/atau perubahan konfigurasi apapun terhadap jaringan akses Voxnet.</li>
            <li>Pengguna  dilarang mengalihkan perjanjian berlangganan ini kepada pihak manapun, tanpa persetujuan tertulis atau seizin dari Voxnet.</li>
            <li>Pengguna dilarang memanfaatkan layanan untuk dijual kembali (resale) jasa dalam bentuk apapun kecuali seizin Voxnet.</li>
            <li>Segala informasi yang tidak sesuai sehubungan dengan perjanjian ini menjadi tangung jawab sepenuhnya oleh Pengguna.</li>
          </ol>
        </li>
        <p className='sub-title'>HAK, KEWAJIBAN DAN LARANGAN VOXNET</p>
        <li>
          <span className='list-title'>HAK VOXNET</span>
          <ol className='common-ol'>
            <li>Voxnet berhak menerima pembayaran biaya berlangganan berdasarkan Perjanjian ini sebelum berakhirnya batas waktu pembayaran.   </li>
            <li>Voxnet berhak untuk meminta dan/atau mengetahui identitas pengguna yang valid.</li>
            <li>Voxnet berhak untuk menolak dan/atau menghentikan layanan, jika didapati adanya penyalahgunaan layanan dan/atau perbuatan yang melanggar ketentuan peraturan perundang-undangan serta penyalahgunaan dalam catatan dari regulator atau mitra operator telekomunikasi yang dilakukan oleh Pengguna.</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>KEWAJIBAN VOXNET</span>
          <ol className='common-ol'>
            <li>Voxnet berkewajiban menjaga kontinyuitas, kualitas dan performa akses telekomunikasi yang berfungsi dengan baik, jika terdapat gangguan yang mengakibtakan tidak berfungsinya layanan maka Voxnet berkewajiban untuk segera memperbaikinya.</li>
            <li>Voxnet berkewajiban memberikan informasi catatan komunikasi dan rincian biaya berlangganan dan pemakaian kepada Pengguna.</li>
            <li>Dalam hal Voxnet tidak dapat menyediakan produk layanan sebagaimana yang telah disepakati Pengguna, dan/atau didapatinya ketidaksesuaian produk layanan sehingga merugikan Penguna, Pengguna harus segera melapor kepada Voxnet dan Voxnet akan memeriksa secara seksama pelaporan yang disampaikan oleh Pengguna dan akan memberitahukan kepada Pengguna apakah produk tersebut dapat ditukar atau jika Pengguna berhak menerima pengembalian dana (dengan sewajarnya) tidak melebihi biaya berlangganan yang telah dibayarkan oleh Pengguna.</li>
            <li>Menolak dan memberikan sanksi kepada Pengguna jika didapati adanya permintaan dan penyalahgunaan produk yang tidak sesuai dengan peraturan perundang-undangan yang berlaku.</li>
            <li>Voxnet berkewajiban untuk menjaga data kerahasiaan Pengguna</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>LARANGAN VOXNET</span>
          <ol className='common-ol'>
            <li>Voxnet dilarang melakukan perubahan-perubahan apapun terhadap jaringan telekomunikasi atau perubahan nomor sambungan yang tidak sesuai dengan peraturan perundang-undangan.</li>
            <li>Voxnet dilarang untuk melakukan hal-hal yang dapat mengakibatkan terjadinya gangguan jaringan telekomunikasi kecuali untuk alasaan peningkatan layanan dan operasional, dengan memberitahukan pemberitahuan terlebih dahulu kepada Pengguna.</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>TAGIHAN DAN BIAYA TARIF BERLANGGANAN</span>
          <ol className='common-ol'>
            <li>Tagihan biaya berlangganan terdiri atas:
              <ol className='common-ol'>
                <li>Biaya berlangganan bulanan (abonemen) telepon, fasilitas dan keunggulan dari produk yang dipilih oleh Pengguna.</li>
                <li>Kelebihan biaya yang melebihi dari fasilitas yang terdapat dalam produk yang dipilih oleh Pengguna.</li>
                <li>Biaya lainnya seperti biaya meterai, PPn, tunggakan dan denda (jika ada), biaya mutasi pergantian layanan/pergantian peningkatan layanan (upgrade).</li>
              </ol>
            </li>
            <li>Informasi mengenai tagihan biaya berlangganan sebagaimana disebutkan dalam point 8.1 ini dapat berubah sewaktu waktu tanpa pemberitahuan terlebih dahulu, dan akan ditagihkan kepada Pengguna pada tagihan periode berikutnya.</li>
            <li>Pengguna akan menerima tagihan bulanan yang harus dibayarkan seluruhnya selambat-lambatnya sebelum tanggal jatuh tempo yang disebutkan pada nota tagihan yang akan dikirmkan kepada Pengguna.</li>
            <li>Dalam hal Pengguna belum juga menerima tagihan sebagaimana yang disebutkan pada point 8.3 ini, pengguna harus melaporkan kepada Voxnet untuk melaporkan belum diterimanya tagihan dan Pengguna tetap berkewajiban untuk memenuhi kewajibannya untuk membayar selambat-lambatnya sebelum tanggal tagihan jatuh tempo walaupun belum menerima tagihan biaya berlangganan.</li>
            <li>Pengguna dengan ini menerima dan mengakui sahnya bukti-bukti penagihan yang didasarkan pada data-data yang tercatat oleh sistem Voxnet secara elektronik.</li>
          </ol>
        </li>

        {/* <p>8a. TATA CARA PEMBAYARAN</p>
        <p>8a.1  Setelah ditanda tanganinya perjanjian ini, Voxnet mengirimkan ke pengguna proposal/quotation sesuai dengan paket yang dipilih</p>
        <p>8b.2  Pengguna wajib mentransf kepada Voxnet seluruh biaya berlangganan sebagaimana dalam ayat (1), sebelum tanggal jatuh tempo (Jam dan Hari Kerja) ke rekening sebegai berikut :</p>
        <p>     A,    Nama Bank        : Bank Mandiri</p>
        <p>          Nomor Rekening    : 129-00-10402143</p>
        <p>          Nama Rekening     : PT. Lima Satu Tiga Global Tel-Access</p>
        <p>          Alamat Bank       : Bank Mandiri Cabang Jakarta Pasar Rebo, Jakarta Timur,</p>
        <p> Indonesia</p>
        <p>     Atau</p>
        <p>     B,    Nama Bank        : United Overseas Bank Limited</p>
        <p>           Nomor Rekening    : 392-901-715-1</p>
        <p>           Nama Rekening     : Voxnet Communication Pte Ltd</p>
        <p>           Alamat Bank       : Jalan Sultan</p> */}

        <li>
          <span className='list-title'>SANKSI</span>
          <ol className='common-ol'>
            <li>Voxnet akan mengirimkan surat peringatan apabila Pengguna tidak melakukan pembayaran biaya berlangganan sesuai dengan jadwal pembayaran yang diberitahukan.</li>
            <li>Pengenaan sanksi terhadap point 3.2 dikenakan sanksi mulai dari mekanisme pengisoliran yang telah ditentukan oleh Voxnet, sampai dengan pemutusan sambungan.</li>
            <li>Pelanggaran atas point 4 perjanjian ini diikuti dengan pemutusan sambungan jaringan telekomunikasi.</li>
            <li>Pengenaan sanksi berdasarkan ketentuan point 9.2 dan 9.3 tidak mengurangi kewajiban pengguna (eks pengguna), akhli waris atau penggantinya untuk melunasi seluruh tunggakan termasuk dendanya terhadap penggunaannya atas layanan produk jaringan komunikasi kepada Voxnet. </li>
            <li>Pengguna dengan ini memahami dan mengetahui dan menyatakan bahwa ketentuan dalam point 9.2 sampai dengan point 9,3 merupakan informasi tentang kemungkinan dikenakan sanksi tersebut, oleh karena itu tidak ada kewajiban bagi Voxnet untuk memberitahukan terlebih dahulu kepada Pengguna atas pengenaan sanksi yang dimaksud.</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>PENGAKHIRAN PERJANJIAN</span>
          <ol className='common-ol'>
            <li>Perjanjian ini dapat berakhir apabila jangka waktu perjanjian telah berakhir atau sesuai dengan paket berlangganan yang dipilih oleh pengguna.</li>
            <li>Pengguna diyatakan pailit, atau dalam proses kepailitan, dalam keadaan likuidasi.</li>
            <li>Perjanjian berlangganan dapat diakhiri sepihak oleh Voxnet, karena diberlakukannya sanksi berdasarkan ketentuan point 9.3 sampai dengan point 9.3 dan/atau karena Voxnet tidak mampu lagi menajdi penyedia jasa telekomunikasi di wilayah lokasi Pengguna.</li>
            <li>Pengguna dapat mengakhiri perjanjian berlangganan secara sepihak dengan cara memberitahukan terlebih dahulu kepada Voxnet, namun Pengguna (eks Pengguna) tetap bertanggung jawab untuk melunasi seluruh tunggakannya kepada Voxnet.</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>KERAHASIAAN</span>
          <p>Masing-masing pihak sepakat untuk menjaga informasi data rahasia seperti identitas diri yang diberikan, dan berjanji mengikat diri untuk tidak memberikan, mengulang, menyampaikan, dan/atau mendistribusikan informasi rahasia tersebut, sepakat menggunakan informasi rahasia yang ada hanya untuk kepentungan dalam Perjanjian ini.</p>
        </li>
        <li>
          FORCE MAJEURE
          <p>Yang dimaksud dengan Force Majeure menurut Perjanjian ini adalah kejadian-kejadian yang dengan segala daya dan upaya tidak dapat diduga dan tidak dapat diatasi oleh Pihak yang mengalaminya yang antara lain adalah sebagai berikut: Bencana Alam, Wabah Penyakit, Pemberontakan/Huru Hara/Perang, padamnya arus listrik pada BTS (Base Transceiver Station), sabotase, peraturan dan/atau larangan Permerintah yang tidak dapat dituntut, maka tidak ada kewajiban bagi masing-masing pihak untuk mengganti kerugian satu kepada yang lain.</p>
        </li>
        <li>
          <span className='list-title'>PENYELESAIAN PERSELISIHAN</span>
          <ol className='common-ol'>
            <li>Apabila terjadi perbedaan pendapat dan/atau perselisihan dalam pelaksanaan dan/atau penafsiran Perjanjian ini, masing-masing pihak sepakat menyelesaikannya secara musyawarah untuk mufakat.</li>
            <li>Apabila secara musyawarah untuk mufakat tidak tercapai perdamaian, maka sepakat untuk membawa semua sengketa yang timbul dari Perjanjian ini melalui Pengadilan Negeri Jakarta Timur, dan keputusan Pengadilan tersebut mengikat untuk dilaksanakan.</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>LAIN-LAIN</span>
          <ol className='common-ol'>
            <li>Pengguna dengan ini menyatakan telah memahami dan mengerti isi Perjanjian ini, sehingga Pengguna membebaskan Voxnet atas setiap tuntutan hukum dari setiap akibat yang timbul dari Perjanjian ini.</li>
            <li>Hal-hal yang belum diatur dalam Perjanjian ini akan diatur lebih lanjut dalam brosur, katalog produk, pengumuman dan/atau pemberitahuan dan/atau berupa dokumen lain yang diterbitkan secara resmi dari waktu ke waktu oleh Voxnet.</li>
          </ol>
        </li>
        <li>
          <span className='list-title'>PENUTUP</span>
          <p>Dalam pelaksanaan Perjanjian ini, masing-masing pihak tunduk pada ketentuan dan peraturan perundang-undangan yang berlaku menurut hukum Republik Indonesia.</p>
        </li>
      </ol>
    </div >
  )
}
