import React from "react";
import { ProductContext, RegisterContext } from "context";

import Card from "components/forms/partials/subpartials/Card";

export default function PackageContainer({ navComponent = null }) {
  const { prodState } = React.useContext(ProductContext);
  const { regisState, handleChange } = React.useContext(RegisterContext);

  const { customerOrderProduct, customerOrderPackage } = regisState;

  const { products, productPackages } = prodState;

  const packageCallback = (package_id) =>
    handleChange({
      customerOrderPackage: productPackages?.[customerOrderProduct][package_id],
      co_name_sub: productPackages?.[customerOrderProduct][package_id].p_name,
    });

  return (
    <div className="PackageContainer bg-white">
      <div className="form-title">
        {`Pilih Paket ${products?.[customerOrderProduct]?.p_name || ""}`}
      </div>
      <div className="row">
        {productPackages
          ? Object.values(productPackages?.[customerOrderProduct] || {}).map(
              ({ p_id, p_name, p_name_description }) => (
                <Card
                  cls="col-lg-divide-5 col-md-4 col-sm-6"
                  key={`card-${p_id}`}
                  title={p_name}
                  p_id={p_id}
                  selected={customerOrderPackage?.p_id === p_id}
                  parentCallback={packageCallback}
                  detail={productPackages?.[customerOrderProduct]?.[p_id]}
                  desc={p_name_description}
                />
              )
            )
          : ""}
      </div>
      {navComponent || ""}
    </div>
  );
}
